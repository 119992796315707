<template>
  <div class="flex-line num-field" :class="{ center }">
    <div
      class="num"
      :style="{
        'font-size': numSize + 'px',
        'margin-right': '20px',
        color: numColor || 'inherit',
      }"
    >
      {{ prefix + formatValue(curVal) }}
    </div>
    <div
      v-if="unit"
      :style="{ 'font-size': unitSize + 'px', color: unitColor || 'inherit' }"
    >
      {{ unit }}
    </div>
    <slot></slot>
  </div>
</template>

<script>
var interval;
export default {
  data() {
    return {
      curVal: 0,
      step: 0,
    };
  },
  components: {},
  props: {
    prefix: { type: String, default: "" },
    digit: { type: Number, default: 0 },
    value: { type: Number, required: true },
    numSize: { type: Number, required: true },
    numColor: { type: String },
    unit: { type: String, default: "" },
    unitSize: { type: Number, default: 0 },
    center: { type: Boolean, default: false },
    unitColor: { type: String },
    padZero: { type: Number, default: 0 },
  },
  watch: {
    value: {
      handler(v) {
        if (this.curVal != v) {
          this.step = 100;
        }
      },
    },
  },
  mounted() {
    this.step = 100;
    interval = setInterval(() => {
      if (this.step > 0) {
        this.step--;
        if (this.step > 0) {
          this.curVal += (this.value - this.curVal) / 5;
        } else {
          this.curVal = this.value;
        }
      }
    }, 20);
  },
  destroyed() {
    clearInterval(interval);
  },
  methods: {
    formatValue(val) {
      var str = val.toFixed(this.digit);
      if (str.length < this.padZero) {
        var tstr = "";
        for (var i = str.length; i < this.padZero; i++) {
          tstr += "0";
        }
        str = tstr + str;
      }
      return str;
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-line {
  &.num-field {
    align-items: baseline !important;
  }
}
</style>
