<template>
  <div v-if="data" class="content" style="margin-top: 50px; position: relative">
    <div class="flex-line center" style="position: relative; z-index: 2">
      <div style="width: 1000px; height: 350px">
        <div class="title1">订单总金额</div>
        <num-field
          :center="true"
          numColor="#2FAFAC"
          :value="totalAmount"
          :digit="2"
          :numSize="150"
          unit="万元"
          :unitSize="70"
        />
      </div>
      <div style="width: 1000px; height: 350px">
        <div class="title1">订单总量</div>
        <num-field
          :center="true"
          numColor="#2FAFAC"
          :digit="2"
          :value="billCount"
          :numSize="150"
          unit="万单"
          :unitSize="70"
        />
      </div>
      <div style="width: 1000px; height: 350px">
        <div class="title1">销售总量</div>
        <num-field
          :center="true"
          numColor="#2FAFAC"
          :value="totalQty"
          :digit="2"
          :numSize="150"
          unit="万吨"
          :unitSize="70"
        />
      </div>
    </div>
    <div
      style="
        display: block;
        margin: auto;
        margin-top: -200px;
        width: 5000px;
        height: 3000px;
        position: relative;
        z-index: 3;
      "
    >
      <div class="floatInfo">
        品质分级金额
        <div class="flex-line item">
          1级瓜 |
          <num-field
            :value="L1Amt"
            :numSize="90"
            :digit="2"
            numColor="#244b66"
            unit="万元"
            :unitSize="70"
          />
        </div>
        <div class="flex-line item">
          2级瓜 |
          <num-field
            :value="L2Amt"
            :digit="2"
            :numSize="90"
            numColor="#244b66"
            unit="万元"
            :unitSize="70"
          />
        </div>
        <div class="flex-line item">
          3级瓜 |
          <num-field
            :value="L3Amt"
            :digit="2"
            :numSize="90"
            numColor="#244b66"
            unit="万元"
            :unitSize="70"
          />
        </div>
      </div>
      <!-- <Echart
        v-if="mapData"
        :options="option"
        :mapData="mapData"
        id="map"
        width="5000px"
        height="2800px"
      ></Echart> -->
    </div>
    <div
      style="
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        text-align: center;
      "
    >
      <video
        style="width: 5000px; height: 3536px"
        muted="true"
        autoplay="true"
        loop="true"
        src="video/map.m4v"
      ></video>
    </div>
  </div>
</template>
<script>
// import Echart from "@/common/echart";
import NumField from "../common/numField.vue";
export default {
  data() {
    return {
      billCount: 0,
      totalQty: 0,
      L1Amt: 0,
      L2Amt: 0,
      L3Amt: 0,
      incTime: 0,
      incAmt: [],
      curIdx: 0,
      option: {
        title: {
          text: "全国销售热力地图",
          textStyle: {
            color: "#244b66",
            fontSize: 70,
          },
          left: "center",
          top: 200,
        },
        grid: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        },
        geo: {
          aspectScale: 0.95,
          show: false,
          map: "map",
          zoom: 1.15,
          center: [104.5, 35],
          itemStyle: {
            normal: {
              borderWidth: 5,
              areaColor: "#00000000", //区域背景色
              label: {
                show: false,
              },
            },
          },
        },
        visualMap: [
          {
            type: "piecewise",
            splitNumber: 3,
            show: true,
            left: "10%",
            bottom: "5%",
            max: 2,
            min: 0,
            itemWidth: 50,
            itemHeight: 100,
            z: 999,
            //calculable: true,
            text: ["销量低", "销量高"],
            inRange: {
              color: ["green", "orange", "red"],
              symbolSize: [100, 400],
              colorAlpha: [1, 1],
              opacity: [1, 1],
            },
            textStyle: {
              color: "#244b66",
              fontSize: 50,
            },
            seriesIndex: 0,
          },
        ],
        series: [
          {
            name: "热力图",
            type: "heatmap",
            coordinateSystem: "geo",
            data: [],
            pointSize: 20,
            blurSize: 50,
          },
        ],
      },
    };
  },
  components: {
    // Echart,
    NumField,
  },
  computed: {
    totalAmount(){
      return this.L1Amt+this.L2Amt+this.L3Amt;
    }
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    mapData: {
      type: Object,
      required: true,
    },
  },
  watch: {
    data: {
      handler(val) {
        this.setAmountNumber(val);
      },
    },
  },
  mounted() {
    this.setAmountNumber(this.data);
    setInterval(() => {
      this.changeIdx();
    }, 3000);
    setInterval(()=>{
      this.incAmount();
    },5000)
  },
  methods: {
    setAmountNumber(val) {
      this.incTime = val.increaseConfig.roundMinute * 12;
      var incL1 = val.increaseConfig.amountIncrease.L1;
      this.incAmt[0] = {min:incL1.min/this.incTime, max:incL1.max/this.incTime};
      var incL2 = val.increaseConfig.amountIncrease.L2;
      this.incAmt[1] = {min:incL2.min/this.incTime, max:incL2.max/this.incTime};
      var incL3 = val.increaseConfig.amountIncrease.L3;
      this.incAmt[2] = {min:incL3.min/this.incTime, max:incL3.max/this.incTime};
      this.billCount = val.summary.billCount;
      this.totalQty=val.summary.totalQty;
      this.L1Amt = val.summary.levelAmount.L1;
      this.L2Amt = val.summary.levelAmount.L2;
      this.L3Amt = val.summary.levelAmount.L3;
    },
    incAmount() {
      if (this.incTime > 0) {
        this.incTime--;
        if (this.incTime <= 0) {
          this.setAmountNumber(this.data);
        }else{
          var totalInc=0;
          var l1Inc = Math.random()*(this.incAmt[0].max-this.incAmt[0].min)+this.incAmt[0].min;
          totalInc+=l1Inc;
          this.L1Amt+=l1Inc;
          var l2Inc = Math.random()*(this.incAmt[1].max-this.incAmt[1].min)+this.incAmt[1].min;
          totalInc+=l2Inc;
          this.L2Amt+=l2Inc;
          var l3Inc = Math.random()*(this.incAmt[2].max-this.incAmt[2].min)+this.incAmt[2].min;
          totalInc+=l3Inc;
          this.L3Amt+=l3Inc;
          this.billCount+=totalInc/this.data.increaseConfig.billPrice;
          this.totalQty+=totalInc/this.data.increaseConfig.weightPrice;
        }
      }
    },
    changeIdx() {
      this.curIdx++;
      if (this.curIdx >= 3) {
        this.curIdx = 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  color: #244b66;
  line-height: 1;
  font-size: 50px;
  height: 100%;
}
.floatInfo {
  text-align: left;
  color: #697c94;
  font-size: 80px;
  position: absolute;
  right: 0px;
  bottom: 1100px;
  .item {
    margin-top: 30px;
  }

  .num-field {
    margin-left: 30px;
  }
}

.title1 {
  color: #697c94;
  font-size: 75px;
  margin-bottom: 20px;
}
</style>
