var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"appRef",attrs:{"id":"index"}},[(!_vm.curPageIdx)?_c('div',{staticClass:"first-page"},[_vm._v(" 请选择当前页面显示分屏号 "),_c('div',[_vm._v(" 销售屏： "),_c('button',{on:{"click":function($event){return _vm.showScreen(1)}}},[_vm._v("屏幕1")]),_c('button',{on:{"click":function($event){return _vm.showScreen(2)}}},[_vm._v("屏幕2")]),_c('button',{on:{"click":function($event){return _vm.showScreen(3)}}},[_vm._v("屏幕3")])]),_c('div',[_vm._v(" 种植屏： "),_c('button',{on:{"click":function($event){return _vm.showScreen(11)}}},[_vm._v("屏幕1")]),_c('button',{on:{"click":function($event){return _vm.showScreen(12)}}},[_vm._v("屏幕2")]),_c('button',{on:{"click":function($event){return _vm.showScreen(13)}}},[_vm._v("屏幕3")])]),_c('div',[_vm._v(" 仓储屏： "),_c('button',{on:{"click":function($event){return _vm.showScreen(21)}}},[_vm._v("屏幕1")]),_c('button',{on:{"click":function($event){return _vm.showScreen(22)}}},[_vm._v("屏幕2")]),_c('button',{on:{"click":function($event){return _vm.showScreen(23)}}},[_vm._v("屏幕3")])])]):_c('div',{staticClass:"bg",class:`bg${_vm.curPageIdx % 10}`},[(_vm.loading)?_c('dv-loading',[_vm._v("Loading...")]):_c('div',{staticClass:"host-body"},[(_vm.curPageIdx == 1)?_c('div',[_c('side-title',{attrs:{"title":"￮ 线上零售"}}),_c('div',{staticClass:"flex-line"},[_c('chart-box',{attrs:{"styleData":{
              width: '3340px',
              height: '1230px',
              margin: '80px 0 0 180px',
            }}},[_c('sale-summary',{attrs:{"data":_vm.sales.saleSummaryData}})],1)],1),_c('div',{staticClass:"flex-line"},[_c('chart-box',{attrs:{"styleData":{
              width: '1880px',
              height: '860px',
              margin: '80px 0 0 180px',
            }}},[_c('supplyer-ladder',{attrs:{"data":_vm.sales.supplyerLadderData}})],1),_c('chart-box',{attrs:{"styleData":{
              width: '1400px',
              height: '860px',
              margin: '80px 0 0 60px',
            }}},[_c('pingjia-radar',{attrs:{"data":_vm.sales.pingjiaRadarData}})],1)],1),_c('div',{staticClass:"flex-line"},[_c('chart-box',{attrs:{"styleData":{
              width: '3340px',
              height: '860px',
              margin: '80px 0 0 180px',
            }}},[_c('brand-ladder',{attrs:{"data":_vm.sales.brandLadderData}})],1)],1)],1):_vm._e(),(_vm.curPageIdx == 2)?_c('div',[_c('center-title',{staticStyle:{"position":"relative","z-index":"10"},attrs:{"title":_vm.title}}),_c('div',{staticStyle:{"position":"absolute","top":"350px","bottom":"0","left":"0","right":"0"}},[_c('map-view',{attrs:{"data":_vm.sales.mapSummaryData,"mapData":_vm.mapData}})],1)],1):_vm._e(),(_vm.curPageIdx == 3)?_c('div',[_c('side-title',{attrs:{"title":"线下批发 ￮","rtl":true}}),_c('div',{staticClass:"flex-line right"},[_c('chart-box',{attrs:{"styleData":{
              width: '3330px',
              height: '1230px',
              margin: '80px 180px 0 0',
            }}},[_c('pifa-summary',{attrs:{"data":_vm.sales.pifaSummaryData}})],1)],1),_c('div',{staticClass:"flex-line right"},[_c('chart-box',{attrs:{"styleData":{
              width: '1870px',
              height: '860px',
              margin: '80px 180px 0 0 ',
            }}},[_c('stock-turn',{attrs:{"data":_vm.sales.stockTurnData}})],1),_c('chart-box',{attrs:{"styleData":{
              width: '1400px',
              height: '860px',
              margin: '80px 60px 0 0',
            }}},[_c('stock-qty',{attrs:{"data":_vm.sales.stockQtyData}})],1)],1),_c('div',{staticClass:"flex-line right"},[_c('chart-box',{attrs:{"styleData":{
              width: '3330px',
              height: '860px',
              margin: '80px 180px 0 0',
            }}},[_c('area-price',{attrs:{"data":_vm.sales.areaPriceData}})],1)],1)],1):_vm._e(),(_vm.curPageIdx == 11)?_c('div',[_c('side-title',{attrs:{"title":"￮ 生产/种植/产地分析","width":1000}}),_c('div',{staticClass:"flex-line"},[_c('chart-box',{attrs:{"styleData":{
              width: '3330px',
              height: '1130px',
              margin: '80px 0 0 180px',
            }}},[_c('province-plant',{attrs:{"data":_vm.plant.privincePlantData}})],1)],1),_c('product-area',{attrs:{"data":_vm.plant.productAreaData}})],1):_vm._e(),(_vm.curPageIdx == 12)?_c('div',[_c('center-title',{staticStyle:{"position":"relative","z-index":"10"},attrs:{"title":_vm.title}}),_c('div',{staticStyle:{"position":"absolute","top":"350px","bottom":"0","left":"0","right":"0"}},[_c('plant-map-view',{attrs:{"data":_vm.plant.mapData}})],1)],1):_vm._e(),(_vm.curPageIdx == 13)?_c('div',[_c('side-title',{attrs:{"title":"哈密瓜标准化示范基地 ￮","width":1000,"rtl":true}}),_c('div',{staticClass:"flex-line right"},[_c('chart-box',{attrs:{"styleData":{
              width: '3330px',
              height: '1230px',
              margin: '80px 180px 0 0',
            }}},[_c('base-summary',{attrs:{"data":_vm.plant.prodBaseData}})],1)],1),_c('div',{staticClass:"flex-line right"},[_c('chart-box',{attrs:{"styleData":{
              width: '1870px',
              height: '860px',
              margin: '80px 180px 0 0 ',
            }}},[_c('enviroment-analize',{attrs:{"data":_vm.plant.envAnlyData}})],1),_c('chart-box',{attrs:{"styleData":{
              width: '1400px',
              height: '860px',
              margin: '80px 60px 0 0',
            }}},[_c('smart-warning',{attrs:{"data":_vm.plant.smartWarningData}})],1)],1),_c('div',{staticClass:"flex-line right"},[_c('chart-box',{attrs:{"styleData":{
              width: '3330px',
              height: '860px',
              margin: '80px 180px 0 0',
            }}},[_c('iot-device',{attrs:{"data":_vm.plant.iotDeviceData}})],1)],1)],1):_vm._e(),(_vm.curPageIdx == 21)?_c('div',[_c('side-title',{attrs:{"title":"￮ 仓储","width":400}}),_c('div',{staticClass:"flex-line"},[_c('chart-box',{attrs:{"styleData":{
              width: '3360px',
              height: '1030px',
              margin: '80px 0 0 180px',
            }}},[_c('stock-base-summary',{attrs:{"data":_vm.stock.stockBaseData}})],1)],1),_c('div',{staticClass:"flex-line"},[_c('chart-box',{attrs:{"styleData":{
              width: '3360px',
              height: '930px',
              margin: '80px 0 0 180px',
            }}},[_c('inout-task')],1)],1),_c('div',{staticClass:"flex-line"},[_c('chart-box',{attrs:{"styleData":{
              width: '1200px',
              height: '970px',
              margin: '80px 0 0 180px',
            }}},[_c('check-report')],1),_c('chart-box',{attrs:{"styleData":{
              width: '2100px',
              height: '970px',
              margin: '80px 0 0 60px',
            }}},[_c('in-stock-report')],1)],1)],1):_vm._e(),(_vm.curPageIdx == 22)?_c('div',[_c('center-title',{staticStyle:{"position":"relative","z-index":"10"},attrs:{"title":_vm.title}}),_c('div',{staticStyle:{"position":"absolute","top":"350px","bottom":"0","left":"0","right":"0"}},[_c('stock-map-view',{attrs:{"data":_vm.stock.mapData}})],1)],1):_vm._e(),(_vm.curPageIdx == 23)?_c('div',[_c('side-title',{attrs:{"title":"运输 ￮","width":400,"rtl":true}}),_c('div',{staticClass:"flex-line right"},[_c('chart-box',{attrs:{"styleData":{
              width: '3340px',
              height: '1020px',
              margin: '80px 180px 0 0',
            }}},[_c('ship-summary')],1)],1),_c('div',{staticClass:"flex-line right"},[_c('chart-box',{attrs:{"styleData":{
              width: '1170px',
              height: '930px',
              margin: '80px 180px 0 0 ',
            }}},[_c('ship-hour')],1),_c('chart-box',{attrs:{"styleData":{
              width: '2100px',
              height: '930px',
              margin: '80px 60px 0 0',
            }}},[_c('ship-time')],1)],1),_c('div',{staticClass:"flex-line right"},[_c('chart-box',{attrs:{"styleData":{
              width: '2050px',
              height: '970px',
              margin: '80px 180px 0 0',
            }}},[_c('transport-summary')],1),_c('chart-box',{attrs:{"styleData":{
              width: '1220px',
              height: '970px',
              margin: '80px 60px 0 0',
            }}},[_c('vehicle-type')],1)],1)],1):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }