<template>
  <div class="content">
    <div class="flex-line" style="position: relative">
      <div class="title1">产量/种植面积</div>
    </div>
    <Echart
      v-if="data"
      :options="chartOption"
      id="productMonthChart"
      width="100%"
      height="730px"
      style="margin-top: -100px;"
    ></Echart>
  </div>
</template>

<script>
import Echart from "@/common/echart";
const colors = ["#2FAFAC", "#F18B4D"];
export default {
  data() {
    return {
      curIdx: 0,
      chartOption: {
        color: colors,
        tooltip: {
          show: false,
        },
        grid: {
          top:200,
          left:100,
          right: 100,
          bottom:50,
        },
        legend: {
          data: ["产量", "种植面积"],
          show: true,
          z: 10,
          top: 0,
          left: "right",
          padding: 10,
          itemGap: 40,
          itemWidth: 50,
          itemHeight: 30,
          textStyle: {
            color: "#697c94",
            fontSize: 50,
          },
        },
        xAxis: [
          {
            type: "category",
            axisTick: {
              alignWithLabel: true,
            },
            axisLabel: {
              color:'#697c94',
              fontSize: 40,
            },
            // prettier-ignore
            data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "单位：吨",
            nameTextStyle: {
              color: "#697c94",
              fontSize: 40,
            },
            nameGap:40,
            position: "left",
            alignTicks: true,
            axisLine: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#697c94",
                width: 3,
              },
            },
            axisLabel: {
              color:colors[0],
              fontSize: 40,
            },
          },
          {
            type: "value",
            name: "单位：万亩",
            nameGap:40,
            nameTextStyle: {
              color: "#697c94",
              fontSize: 40,
            },
            position: "right",
            alignTicks: true,
            axisLine: {
              show: false,
            },
            axisLabel: {
              color:colors[1],
              fontSize: 40,
            },
          },
        ],
        series: [
          {
            name: "产量",
            type: "bar",
            barWidth: 26,
            data: [],
          },
          {
            name: "种植面积",
            type: "line",
            yAxisIndex: 1,
            lineStyle:{
              width:5,
            },
            itemStyle:{
              borderColor:'white',
              borderWidth:4,
            },
            symbol:'circle',
            symbolSize:26,
            data: [],
          },
        ],
      },
    };
  },
  components: { Echart },
  props: {
    data: {},
    idx:{type:Number},
  },
  watch: {
    data: {
      handler(val) {
        this.refreshChart(val,this.idx);
      },
    },
    idx: {
      handler(val) {
        this.refreshChart(this.data,val);
      },
    },
  },
  mounted() {
    if (this.data) {
      this.refreshChart(this.data, this.idx);
    }
  },
  methods: {
    refreshChart(data, idx) {
      var opt = Object.assign({}, this.chartOption);
      opt.series[0].data=data.monthQty[idx];
      opt.series[1].data = data.monthArea[idx];
      this.chartOption=opt;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  color: #244b66;
  line-height: 1;
}
.title1 {
  font-size: 75px;
  margin-bottom: 20px;
}
.changeBtn {
  width: 300px;
  height: 72px;
  border: solid 4px #697c94;
  margin-right: 20px;
  font-size: 50px;
  line-height: 70px;
  text-align: center;
  cursor: pointer;
  transition: color 1s, background-color 1s;

  &.active {
    background-color: #528be4;
    color: white;
  }
}
</style>
