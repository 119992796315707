<template>
  <div class="content" style="position: relative">
    <div class="flex-line">
      <div class="title1">平均到货时长</div>
      <div style="position: absolute; right: 0">单位：小时</div>
    </div>
    <Echart
      :options="chartOption"
      width="100%"
      height="800px"
      style="margin-top: -100px"
    ></Echart>
  </div>
</template>

<script>
import Echart from "@/common/echart";
const color2 = [
  {
    type: "linear",
    x: 0,
    y: 0,
    x2: 1,
    y2: 0,
    colorStops: [
      {
        offset: 0,
        color: "#F18B4D", // 0% 处的颜色
      },
      {
        offset: 1,
        color: "#F6C76D", // 100% 处的颜色
      },
    ],
  },
];

export default {
  data() {
    return {
      curIdx: 0,
      chartOption: {
        color: color2,
        tooltip: {
          show: false,
        },
        grid: {
          top: 120,
          left: 120,
          right: 30,
          bottom: 50,
        },
        legend: {
          show: false,
        },
        xAxis: [
          {
            type: "value",
            axisTick: {
              alignWithLabel: true,
            },
            axisLabel: {
              color: "#697c94",
              fontSize: 45,
            },
            axisLine: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#697c94",
                width: 3,
              },
            },
          },
        ],
        yAxis: [
          {
            type: "category",
            axisLabel: {
              color: "#697c94",
              fontSize: 55,
            },
            nameGap: 40,
            position: "left",
            alignTicks: true,
            inverse: true,
            data: ["公路", "铁路", "航空", "水路", "其他"],
          },
        ],
        series: [
          {
            type: "bar",
            barWidth: 50,
            data: [42, 27, 18, 63, 45],
          },
        ],
      },
      data: { val: [42, 27, 18, 63, 45] },
    };
  },
  components: { Echart },
  props: {},
  watch: {
    data: {
      handler(val) {
        this.refreshChart(val);
      },
    },
  },
  mounted() {
    if (this.data) {
      this.refreshChart(this.data);
    }
    setInterval(() => {
      this.refreshChart(this.data);
    }, 3000);
  },
  methods: {
    refreshChart(data) {
      var opt = Object.assign({}, this.chartOption);
      var val = Object.assign([], data.val);
      for (var i = 0; i < val.length; i++) {
        val[i] *= 0.9 + Math.random() * 0.2;
      }
      console.log(val);
      opt.series[0].data = val;
      this.chartOption = opt;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  color: #697c94;
  line-height: 1;
  font-size: 50px;
}
.title1 {
  font-size: 75px;
  margin-bottom: 20px;
}
.changeBtn {
  width: 300px;
  height: 72px;
  border: solid 4px #697c94;
  margin-right: 20px;
  font-size: 50px;
  line-height: 70px;
  text-align: center;
  cursor: pointer;
  transition: color 1s, background-color 1s;

  &.active {
    background-color: #528be4;
    color: white;
  }
}
</style>
