<template>
  <div class="content" style="position: relative">
    <div class="flex-line">
      <div class="title1">智能预警</div>
    </div>
    <img
      class="chartbg"
      style="position: absolute; top: 50px; left: 260px"
      src="/img/radarbg.png"
    />
    <div class="legendPanel">
      <div class="flex-line">
        <div class="dot" :style="{ 'background-color': colors[0] }"></div>
        <div>正常</div>
      </div>
      <div class="flex-line">
        <div class="dot" :style="{ 'background-color': colors[1] }"></div>
        <div>警惕</div>
      </div>
      <div class="flex-line">
        <div class="dot" :style="{ 'background-color': colors[2] }"></div>
        <div>报警</div>
      </div>
    </div>
    <Echart
      v-if="data"
      :options="chartOption"
      id="smartWarningChart"
      width="100%"
      height="730px"
      style="position: relative; z-index: 2; margin-top: -100px"
    ></Echart>
  </div>
</template>

<script>
import Echart from "@/common/echart";
const colors = ["#2FAFAC", "#F18B4D", "#F56363"];
const names = ["湿度", "温度", "紫外线", "氨气值", "土壤", "pm10", "pm2.5"];
const padding = [
  [10, 20, 10, 10],
  [10, 10, 10, 20],
];
const borderRadius = [
  [0, 30, 30, 0],
  [30, 0, 0, 30],
];
export default {
  data() {
    return {
      colors,
      curIdx: 0,
      chartOption: {
        legend: {
          show: false,
        },
        grid: {
          top: 200,
          left: 100,
          right: 100,
          bottom: 50,
        },
        radar: {
          radius: "90%",
          center: ["50%", "55%"],
          shape: "circle",
          axisTick: { show: false },
          axisLabel: { show: false },
          axisLine: { show: false },
          axisName: { show: false },
          splitLine: { show: false },
          splitArea: { show: false },
          indicator: [
            { name: names[0], max: 100 },
            { name: names[1], max: 100 },
            { name: names[2], max: 100 },
            { name: names[3], max: 100 },
            { name: names[4], max: 100 },
            { name: names[5], max: 100 },
            { name: names[6], max: 100 },
          ],
        },
      },
    };
  },
  components: { Echart },
  props: {
    data: {},
  },
  watch: {
    data: {
      handler(val) {
        this.refreshChart(val);
      },
    },
  },
  mounted() {
    if (this.data) {
      this.refreshChart(this.data);
    }
    setInterval(() => {
      this.changeIdx();
    }, 3000);
  },
  methods: {
    changeIdx() {
      this.curIdx++;
      if (this.curIdx >= 3) {
        this.curIdx = 0;
      }
      this.refreshChart(this.data);
    },
    refreshChart(data) {
      var values = data[this.curIdx];
      var series = [];
      values.forEach((v, idx) => {
        var vrr = [];
        for (var i = 0; i < values.length; i++) {
          vrr[i] = i == idx ? v : 1000;
        }
        var color = v < 60 ? colors[0] : v < 80 ? colors[1] : colors[2];
        series[idx] = {
          name: names[idx],
          type: "radar",
          symbol: "circle",
          label: {
            show: true,
            fontSize: 40,
            position: idx < 4 ? "left" : "right",
            formatter: "{a}",
            color: "white",
            backgroundColor: color,
            padding: padding[idx < 4 ? 0 : 1],
            borderRadius: borderRadius[idx < 4 ? 0 : 1],
          },
          lineStyle: { width: 0 },
          itemStyle: { color },
          symbolSize: 20,
          data: [{ value: vrr }],
        };
      });
      var opt = Object.assign({}, this.chartOption);
      opt.series = series;
      this.chartOption = opt;
    },
  },
};
</script>

<style lang="scss" scoped>
@-webkit-keyframes changeright {
  0% {
    -webkit-transform: rotate(0deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}
.legendPanel {
  position: absolute;
  font-size: 50px;
  z-index: 10;
  right: 30px;
  top: 10px;

  .flex-line{
    margin-top: 20px;

    .dot{
      width:30px;
      height:30px;
      border-radius: 100%;
      margin-right: 20px;
    }
  }
}

.chartbg {
  -webkit-animation: changeright 6s linear infinite;
}

.content {
  color: #244b66;
  line-height: 1;
}
.title1 {
  font-size: 75px;
  margin-bottom: 20px;
}
.changeBtn {
  width: 300px;
  height: 72px;
  border: solid 4px #697c94;
  margin-right: 20px;
  font-size: 50px;
  line-height: 70px;
  text-align: center;
  cursor: pointer;
  transition: color 1s, background-color 1s;

  &.active {
    background-color: #528be4;
    color: white;
  }
}
</style>
