<template>
  <div class="content">
    <div class="flex-line" style="position: relative">
      <div class="title1">应季瓜/反季瓜产量</div>
    </div>
    <Echart
      v-if="data"
      :options="chartOption"
      id="seasonProductChart"
      width="100%"
      height="730px"
      style="margin-top: -100px"
    ></Echart>
  </div>
</template>

<script>
import Echart from "@/common/echart";
const colors = [
  {
    type: "linear",
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [
      {
        offset: 0,
        color: "#548CE3", // 0% 处的颜色
      },
      {
        offset: 1,
        color: "#548CE300", // 100% 处的颜色
      },
    ],
  },
  {
    type: "linear",
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [
      {
        offset: 0,
        color: "#F56060", // 0% 处的颜色
      },
      {
        offset: 1,
        color: "#F5606000", // 100% 处的颜色
      },
    ],
  },
];
export default {
  data() {
    return {
      curIdx: 0,
      chartOption: {
        color: colors,
        tooltip: {
          show: false,
        },
        grid: {
          top: 200,
          left: 100,
          right: 100,
          bottom: 50,
        },
        legend: {
          data: ["应季瓜", "反季瓜"],
          show: true,
          z: 10,
          top: 0,
          left: "right",
          padding: 10,
          itemGap: 40,
          itemWidth: 50,
          itemHeight: 30,
          textStyle: {
            color: "#697c94",
            fontSize: 50,
          },
        },
        xAxis: [
          {
            type: "category",
            axisTick: {
              alignWithLabel: true,
            },
            axisLabel: {
              color: "#697c94",
              fontSize: 40,
            },
            // prettier-ignore
            data: ['第一季度', '第二季度', '第三季度', '第四季度'],
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "单位：吨",
            nameTextStyle: {
              color: "#697c94",
              fontSize: 40,
            },
            nameGap:40,
            position: "left",
            alignTicks: true,
            axisLine: {
              show: false,
              lineStyle: {
                color: colors[0],
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#697c94",
                width: 3,
              },
            },
            axisLabel: {
              color: "#697c94",
              fontSize: 40,
            },
          },
        ],
        series: [
          {
            name: "应季瓜",
            type: "bar",
            data: [],
          },
          {
            barGap: "-60%",
            name: "反季瓜",
            type: "bar",
            data: [],
          },
        ],
      },
    };
  },
  components: { Echart },
  props: {
    data: {},
    idx: { type: Number },
  },
  watch: {
    data: {
      handler(val) {
        this.refreshChart(val, this.idx);
      },
    },
    idx: {
      handler(val) {
        this.refreshChart(this.data, val);
      },
    },
  },
  mounted() {
    if (this.data) {
      this.refreshChart(this.data, this.idx);
    }
  },
  methods: {
    refreshChart(data, idx) {
      var opt = Object.assign({}, this.chartOption);
      opt.series[0].data = data.seasonProd[idx];
      opt.series[1].data = data.antiSeasonProd[idx];
      this.chartOption = opt;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  color: #244b66;
  line-height: 1;
}
.title1 {
  font-size: 75px;
  margin-bottom: 20px;
}
.changeBtn {
  width: 300px;
  height: 72px;
  border: solid 4px #697c94;
  margin-right: 20px;
  font-size: 50px;
  line-height: 70px;
  text-align: center;
  cursor: pointer;
  transition: color 1s, background-color 1s;

  &.active {
    background-color: #528be4;
    color: white;
  }
}
</style>
