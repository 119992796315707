<template>
  <div class="content" style="margin-top: 50px; position: relative">
    <div
      style="
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        text-align: center;
      "
    >
      <div
        class="map-contain"
        v-if="data"
        style="
          display: inline-block;
          position: relative;
          width: 5000px;
          height: 3536px;
        "
        @mousedown="mousedown"
      >
        <div
          v-for="(p, idx) in data.points"
          :key="p.name"
          class="point"
          :class="{ active: idx == curIdx }"
          :style="{
            left: p.location[0] - 100 + 'px',
            top: p.location[1] - 100 + 'px',
          }"
        >
          <img :src="`/img/dian2.webp`" />
          <div class="infoPanel">
            <div class="main">
              <div class="name">
                <div class="tt">当前产地库容情况</div>
                <div class="t">{{ p.name }}</div>
              </div>
              <div class="flex-line info1">
                <div class="info">仓库数量</div>
                <num-field
                  :value="p.num"
                  numColor="#2c948f"
                  :numSize="90"
                  :unitSize="60"
                  unit="个"
                ></num-field>
              </div>
              <div class="flex-line info2">
                <div class="item">
                  <div class="label">库存量</div>
                  <num-field
                    :value="p.stockQty"
                    numColor="#2c948f"
                    :numSize="90"
                    :unitSize="60"
                    unit="吨"
                  ></num-field>
                </div>
                <div class="v-split" style="height:150px"></div>
                <div class="item">
                  <div class="label">入库量</div>
                  <num-field
                    :value="p.inQty"
                    numColor="#2c948f"
                    :numSize="90"
                    :unitSize="60"
                    unit="吨"
                  ></num-field>
                </div>
                <div class="v-split" style="height:150px"></div>
                <div class="item">
                  <div class="label">出库量</div>
                  <num-field
                    :value="p.outQty"
                    numColor="#2c948f"
                    :numSize="90"
                    :unitSize="60"
                    unit="吨"
                  ></num-field>
                </div>
              </div>
            </div>
            <div class="bottom"></div>
          </div>
        </div>
        <div
          class="title1"
          style="
            position: absolute;
            top: 600px;
            left: 0;
            right: 0;
            text-align: center;
          "
        >
          各大场地仓储分布
        </div>
        <video
          style="width: 5000px; height: 3536px"
          muted="true"
          autoplay="true"
          loop="true"
          src="video/map-blank.m4v"
        ></video>
      </div>
    </div>
  </div>
</template>
<script>
import NumField from "../common/numField.vue";
export default {
  data() {
    return {
      curIdx: 0,
    };
  },
  components: { NumField },
  computed: {},
  props: {
    data: {
      type: Object,
    },
  },
  watch: {
    data: {},
  },
  mounted() {
    setInterval(() => {
      this.curIdx++;
      if (this.curIdx >= this.data.points.length) {
        this.curIdx = 0;
      }
      console.log(this.curIdx);
    }, 5000);
  },
  methods: {
    mousedown(e) {
      var { offsetX, offsetY } = e;
      console.log([offsetX, offsetY]);
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  color: #244b66;
  line-height: 1;
  font-size: 50px;
  height: 100%;
}
.map-contain {
  .point {
    width: 200px;
    height: 200px;
    overflow: visible;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    .infoPanel {
      position: absolute;
      z-index: 10;
      bottom: 150px;
      opacity: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      .main {
        background: #ffffff80;
        backdrop-filter: blur(20px);
        width: 570px;
        height: 300px;
        border-radius: 40px;
        transition: width 1s, height 1s;
        .tt {
          display: none;
        }
      }
      .bottom {
        background: #ffffff80;
        backdrop-filter: blur(20px);
        width: 70px;
        height: 40px;
        clip-path: polygon(0 0, 70px 0px, 35px 40px);
      }
      transition: opacity 0.5s;
      .name {
        padding-top: 20px;
        width: 80%;
        margin: auto;
        height: 150px;
        font-size: 55px;
        line-height: 130px;
        border-bottom: 3px solid #818a93;
        margin-bottom: 40px;
      }
      .info {
        margin: 0 30px 0 0;
        width: 200px;
        font-size: 50px;
        color: #697c94;
      }
      .info1 {
        opacity: 1;
        transition: opacity 1s;
        justify-content: center;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 22px;
      }
      .info2 {
        opacity: 0;
        justify-content: center;
        transition: opacity 1s;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 22px;

        .item{
          padding:20px;
          text-align: left;
          width:330px
        }
      }
      .percent {
        width: 250px;
        font-size: 90px;
        color: #2c948f;
      }
    }
    &.active {
      .main {
        width: 1300px;
        height:380px;
        .tt {
          position: absolute;
          display: block;
          left: 80px;
        }
        .t {
          position: absolute;
          right: 80px;
        }
        .info1 {
          opacity: 0;
        }

        .info2{
          opacity: 1;
        }
      }
    }
  }
}
.floatInfo {
  text-align: left;
  color: #697c94;
  font-size: 80px;
  position: absolute;
  right: 0px;
  bottom: 1100px;
  .item {
    margin-top: 30px;
  }

  .num-field {
    margin-left: 30px;
  }
}

.title1 {
  color: #697c94;
  font-size: 100px;
  letter-spacing: 20px;
  margin-bottom: 20px;
}

.legendPanel {
  position: absolute;
  left: 1000px;
  bottom: 500px;
  width: 550px;
  height: 250px;
  border: 2px solid #697c94;
  border-radius: 40px;
  background-color: #dae6ed;
  color: #697c94;
  padding: 40px;
  .dot {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    margin: 30px 30px;
  }
}
</style>
