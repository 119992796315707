<template>
  <div class="content" style="position: relative">
    <div class="flex-line">
      <div class="title1">运输时间分布</div>
    </div>
    <Echart :options="chartOption" width="100%" height="750px"></Echart>
  </div>
</template>

<script>
import Echart from "@/common/echart";
const colors = [
  "#F56363",
  "#C5312F",
  "#B12B28",
  "#962320",
  "#7C1A17",
  "#6E0804",
];
export default {
  data() {
    return {
      curIdx: 0,
      chartOption: {
        color: colors,
        toolbox: {
          show: false,
        },
        series: [
          {
            label: {
              formatter: ["{title|{b}}{abg|}", "{value|{c}%}"].join("\n"),
              rich: {
                title: {
                  color: "#244b66",
                  align: "center",
                  height: 100,
                  fontSize: 50,
                },
                value: {
                  color: "#244b66",
                  align: "center",
                  fontSize: 75,
                },
              },
              alignTo: "labelLine",
            },
            labelLine: {
              length: 20,
              length2: 20,
              lineStyle: {
                color: "#244b66",
                width: 5,
              },
            },
            type: "pie",
            radius: ["0", "60%"],
            center: ["50%", "50%"],
            roseType: "area",
            data: [
              { value: 32, name: "18·24点" },
              { value: 28, name: "0-6点" },
              { value: 17, name: "6-9点" },
              { value: 13, name: "9-12点" },
              { value: 11, name: "15-18点" },
              { value: 9, name: "12-15点" },
            ],
          },
        ],
      },
      data: { val: [32, 28, 17, 13, 11, 9] },
    };
  },
  components: { Echart },
  props: {},
  watch: {
    data: {
      handler(val) {
        this.refreshChart(val);
      },
    },
  },
  mounted() {
    this.oriValues=Object.assign([],this.chartOption.series[0].data);
    if (this.data) {
      this.refreshChart(this.data);
    }
    setInterval(() => {
      this.refreshChart(this.data);
    }, 3000);
  },
  methods: {
    refreshChart(data) {
      var opt = Object.assign({}, this.chartOption);
      var val = Object.assign([], data.val);
      var t = 0;
      for (var i = 0; i < val.length; i++) {
        val[i] *= 0.8 + Math.random() * 0.4;
        t += val[i];
      }
      var p = 100;
      for (i = 0; i < val.length - 1; i++) {
        p -= val[i] = ((val[i] * 100) / t).toFixed(1);
      }
      val[val.length - 1] = p.toFixed(1);
      var chartData=Object.assign([],this.oriValues);
      for (i = 0; i < val.length; i++) {
        chartData[i].value = val[i];
      }
      opt.series[0].data = chartData.sort(
        (a, b) => b.value - a.value
      );
      this.chartOption = opt;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  color: #244b66;
  line-height: 1;
}
.title1 {
  font-size: 75px;
  margin-bottom: 20px;
}
.changeBtn {
  width: 300px;
  height: 72px;
  border: solid 4px #697c94;
  margin-right: 20px;
  font-size: 50px;
  line-height: 70px;
  text-align: center;
  cursor: pointer;
  transition: color 1s, background-color 1s;

  &.active {
    background-color: #528be4;
    color: white;
  }
}
</style>
