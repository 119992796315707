<template>
  <div class="flex-line pifa-item" :class="{ active }">
    <div class="name">· {{ data.name }}</div>
    <div class="value">{{ data.num }}</div>
    <div class="value">{{ data.value }}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  props: {
    data: { type: Object, required: true },
    active: { type: Boolean, default: false },
  },
  watch: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
.flex-line {
  margin: 25px 0 0;
  align-items: baseline;
  justify-content: space-between;
}

.pifa-item {
  padding: 20px;
  color: #7a8ca2;
  font-size: 60px;
  background-color: #c2d2df;
  transition: color 1s, background-color 1s;

  &.active {
    color: white;
    background-color: #2fafac;
  }
}
.name {
  width: 300px;
}
.value {
  width: 300px;
  text-align: center;
}
</style>
