<template>
  <div class="content">
    <div class="flex-line">
      <div style="width: 100%; height: 1100px">
        <div class="title0">仓储实时收/发作业动态监测</div>
        <div class="flex-line bill-title">
          <div>时间</div>
          <div>仓库</div>
          <div style="flex: 2">订单编号</div>
          <div>物流信息</div>
          <div>品类信息</div>
          <div>重量（吨）</div>
        </div>
        <inoutTaskItem
          v-for="(item,idx) in tasks"
          :key="item.orderNo"
          :class = {active:!idx}
          :data="item"
        ></inoutTaskItem>
      </div>
    </div>
  </div>
</template>

<script>
import inoutTaskItem from "./inoutTaskItem.vue";
var nextTaskTime;
const warehouses = [
  "南湖仓库",
  "三塘湖仓库",
  "淖毛湖仓库",
  "鄯善仓库",
  "喀纳斯仓库",
];
const types = ["西州蜜瓜", "网纹蜜瓜", "阳香瓜", "东湖瓜"];
export default {
  data() {
    return {
      cityIdx: 0,
      taskCount: 4,
      tasks: [],
    };
  },
  components: { inoutTaskItem },
  props: {
    data: {
      type: Object,
    },
  },
  watch: {},
  mounted() {
    var t = -100;
    while (t < 0) {
      this.createTask(t);
      t += Math.random() * 10 + 10;
    }
    nextTaskTime = Math.random() * 2;
    setInterval(() => {
      if (nextTaskTime > 0) {
        nextTaskTime--;
        console.log(nextTaskTime);
        if (nextTaskTime <= 0) {
          this.createTask(0);
          nextTaskTime = Math.random() * 2;
        }
      }
    }, 3000);
  },
  methods: {
    formatNum(val) {
      if (val < 10) {
        return "0" + val;
      } else {
        return val;
      }
    },
    createTask(deltaSecond) {
      var t = new Date().valueOf() + deltaSecond * 1000;
      var d = new Date(t);
      var time = `${this.formatNum(d.getHours())}:${this.formatNum(
        d.getMinutes()
      )}:${this.formatNum(d.getSeconds())}`;
      this.tasks.unshift({
        time,
        warehouse: warehouses[Math.floor(Math.random() * warehouses.length)],
        orderNo: t.toFixed(0),
        prodName: types[Math.floor(Math.random() * types.length)],
        qty: (500 + Math.random() * 500).toFixed(2),
      });
      if (this.tasks.length > this.taskCount) {
        this.tasks.pop();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  color: #244b66;
  line-height: 1;
}
.bill-title {
  padding: 20px;
  color: #697c94;
  font-size: 60px;
  justify-content: space-between;
  div {
    text-align: center;
    flex: 1;
  }
}
.title0 {
  font-size: 75px;
  margin-bottom: 20px;
}
.title1 {
  color: #697c94;
  font-size: 75px;
  margin-bottom: 20px;
}
.title2 {
  font-size: 60px;
  margin-right: 40px;
}
.changeBtn {
  width: 450px;
  height: 72px;
  border: solid 4px #697c94;
  margin-right: 20px;
  font-size: 50px;
  line-height: 70px;
  text-align: center;
  cursor: pointer;
  transition: color 1s, background-color 1s;

  &.active {
    background-color: #528be4;
    color: white;
  }
}
</style>
