<template>
  <div class="flex-line">
    <div
      v-for="(item, idx) in list"
      :key="idx"
      class="changeBtn"
      :class="{ active: value == idx }"
      @click="setValue(idx)"
      :style="{
        width: (item.width || width) + 'px',
        height: height + 'px',
        'margin-right': margin + 'px',
      }"
    >
      {{ item.name || item }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      interval: null,
    };
  },
  props: {
    value: { type: Number, default: 0 },
    changeInterval: { type: Number, default: 0 },
    list: { type: Array, required: true },
    width: { type: Number, default: 300 },
    height: { type: Number, default: 72 },
    margin: { type: Number, default: 20 },
  },
  watch: {
    changeInterval: {
      handler(val) {
        if (this.interval) clearInterval(this.interval);
        this.interval = setInterval(() => {
          this.swapIdx();
        }, val);
      },
    },
  },
  mounted() {
    if (this.changeInterval) {
      this.interval = setInterval(() => {
        this.swapIdx();
      }, this.changeInterval);
    }
  },
  destroyed() {
    if (this.interval) clearInterval(this.interval);
  },
  methods: {
    swapIdx() {
      var val = this.value + 1;
      if (val >= this.list.length) val = 0;
      this.$emit("input", val);
    },
    setValue(idx) {
      this.$emit("input", idx);
    },
  },
};
</script>

<style lang="scss" scoped>
.changeBtn {
  border: solid 4px #697c94;
  font-size: 50px;
  line-height: 70px;
  text-align: center;
  cursor: pointer;
  transition: color 1s, background-color 1s;
  &.active {
    background-color: #528be4;
    color: white;
  }
}
</style>
