<template>
  <div class="flex-line">
    <div class="idxNum">
      {{ formatNumber(data.idx) }}
    </div>
    <EChart
      class="chart"
      ref="chart"
      :options="option"
      width="250px"
      height="250px"
    ></EChart>
    <div style="width: 300px">
      <div class="name">{{ data.name }}</div>
      <num-field
        :numSize="40"
        :value="data.value"
        :unitSize="40"
        unit="万元"
      ></num-field>
    </div>
  </div>
</template>

<script>
import EChart from "@/common/echart";
//import Charts from "@jiaminghi/charts";
import NumField from "./numField.vue";
//var myChart;
export default {
  data() {
    return {
      option: {
        color: ["#244b66", "#AFC3D6"],
        series: [
          {
            type: "pie",
            radius: ["80%", "100%"],
            title: {
              show: false,
            },
            data: [
              {
                name: "20%",
                value: 20,
                label: {
                  position: "center",
                  color: "#244b66",
                  fontSize: 65,
                },
              },
              {
                value: 80,
              },
            ],
          },
        ],
      },
    };
  },
  components: { NumField, EChart },
  props: {
    data: { type: Object, required: true },
  },
  watch: {
    data: {
      handler(val) {
        this.setChartOpt(val);
      },
    },
  },
  mounted() {
    //const container = this.$refs.chart;
    //myChart = new Charts(container);
    this.setChartOpt();
  },
  methods: {
    formatNumber(num) {
      return num < 10 ? "0" + num : num;
    },
    setChartOpt(val) {
      val = val || this.data;
      var newOpt = Object.assign({}, this.option);
      var it = newOpt.series[0];
      it.data[0].value = val.percent;
      it.data[0].name = val.percent + "%";
      it.data[1].value = 100 - val.percent;
      newOpt.color[0] = val.color;
      this.option = newOpt;
      //myChart.setOption(this.option);
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-line {
  margin: 10px 0;
  align-items: baseline;
  color: #244b66;
  font-size: 60px;
}
.idxNumOut {
  width: 90px;
  height: 90px;
  border-radius: 100%;
  border: 2px solid white;
  background: transparent;
  padding: 10px;
  margin: 0 20px;
  .idxNum {
    width: 70px;
    height: 70px;
    font-size: 50px;
    border-radius: 100%;
    color: white;
    line-height: 70px;
    text-align: center;
  }
}
.name {
  font-size: 40px;
  color: #697c94;
}
.barbg {
  margin: 0 20px;
  width: 900px;
  height: 16px;
  border-radius: 8px;
  background-color: #bdccd8;

  .bar {
    height: 16px;
    border-radius: 8px;
  }
}
.chart {
  width: 250px;
  height: 250px;
  margin: 30px 20px;
}
.value {
  width: 250px;
  text-align: right;
}
</style>
