<template>
  <div class="content">
    <div class="flex-line" style="position: relative">
      <div class="title1">周转数据分析</div>
      <div
        style="position: absolute; right: 0; color: #697c94; font-size: 50px"
      >
        单位 : 天
      </div>
    </div>
    <Echart
      v-if="data"
      :options="optionBar"
      id="stockTurnChart"
      width="100%"
      height="630px"
    ></Echart>
  </div>
</template>

<script>
import Echart from "@/common/echart";
export default {
  data() {
    return {
      curIdx:0,
      optionBar: {
        legend: {
          show: true,
          z: 10,
          data: ["一级瓜", "二级瓜", "三级瓜"],
          top: 0,
          left: "left",
          padding: 30,
          itemGap: 30,
          itemWidth: 40,
          itemHeight: 40,
          textStyle: {
            color: "#697c94",
            fontSize: 40,
          },
        },
        grid: {
          top: 120,
          left: 100,
          right: 0,
        },
        xAxis: [
          {
            axisLabel: {
              color: "#697c94",
              fontSize: 40,
            },
            type: "category",
            axisTick: { show: false },
            data: [],
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              color: "#697c94",
              fontSize: 40,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#697c94",
                width: 3,
              },
            },
          },
        ],
        series: [
          {
            name: "一级瓜",
            type: "bar",
            data: [],
            barWidth: 25,
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#43cecb", // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: "#30b0ad", // 100% 处的颜色
                },
              ],
            },
          },
          {
            name: "二级瓜",
            type: "bar",
            data: [],
            barWidth: 25,
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#f7c76e", // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: "#f18c4e", // 100% 处的颜色
                },
              ],
            },
          },
          {
            name: "三级瓜",
            type: "bar",
            data: [],
            barWidth: 25,
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#ff9090", // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: "#f56565", // 100% 处的颜色
                },
              ],
            },
          },
        ],
      },
    };
  },
  components: { Echart },
  props: {
    data: {
    },
  },
  watch: {
    data: {
      handler(val) {
        this.refreshChart(val);
      },
    },
  },
  mounted() {
    if (this.data) {
      this.refreshChart(this.data);
    }
    setInterval(() => {
      this.changeIdx();
    }, 3000);
  },
  methods: {
    changeIdx(){
      this.curIdx++;
      if(this.curIdx>=3){
        this.curIdx=0;
      }
      this.refreshChart(this.data);
    },
    refreshChart(data) {
      var barOpt = Object.assign({}, this.optionBar);
      var barData = data.stockTurn[this.curIdx];
      barOpt.xAxis[0].data = barData.xAxis;
      barOpt.series[0].data = barData.L1;
      barOpt.series[1].data = barData.L2;
      barOpt.series[2].data = barData.L3;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  color: #244b66;
  line-height: 1;
}
.title1 {
  font-size: 75px;
  margin-bottom: 20px;
}
</style>
