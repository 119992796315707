<template>
  <div class="content">
    <div class="title0">运输概况</div>
    <div class="flex-line">
      <div class="contentItem">
        <div class="label">车辆总数(辆)</div>
        <bg-num-field
          :value="showData[0].num"
          numColor="white"
          :numSize="100"
        ></bg-num-field>
        <div class="label">较去年同期</div>
        <num-field
          prefix="+"
          :value="showData[0].inc"
          :numSize="80"
          numColor="#244b66"
          unit="辆"
          :unitSize="60"
        ></num-field>
        <div class="label">同比增长</div>
        <num-field
          :value="showData[0].percent"
          :digit="1"
          :numSize="80"
          numColor="#244b66"
          unit="%"
          :unitSize="60"
        >
          <img src="/img/up.webp" />
        </num-field>
      </div>
      <div class="v-split" style="height: 700px" />
      <div class="contentItem">
        <div class="label">今年总运输量(次)</div>
        <bg-num-field
          :value="showData[1].num"
          numColor="white"
          :numSize="100"
        ></bg-num-field>
        <div class="label">较去年同期</div>
        <num-field
          prefix="+"
          :value="showData[1].inc"
          :numSize="80"
          numColor="#244b66"
          unit="次"
          :unitSize="60"
        ></num-field>
        <div class="label">同比增长</div>
        <num-field
          :value="showData[1].percent"
          :digit="1"
          :numSize="80"
          numColor="#244b66"
          unit="%"
          :unitSize="60"
        >
          <img src="/img/up.webp" />
        </num-field>
      </div>
      <div class="v-split" style="height: 700px" />
      <div class="contentItem">
        <div class="label">今年总调运量(万吨)</div>
        <bg-num-field
          :value="showData[2].num"
          numColor="white"
          :numSize="100"
        ></bg-num-field>
        <div class="label">较去年同期</div>
        <num-field
          prefix="+"
          :value="showData[2].inc"
          :numSize="80"
          numColor="#244b66"
          unit="万吨"
          :unitSize="60"
        ></num-field>
        <div class="label">同比增长</div>
        <num-field
          :value="showData[2].percent"
          :digit="1"
          :numSize="80"
          numColor="#244b66"
          unit="%"
          :unitSize="60"
        >
          <img src="/img/up.webp" />
        </num-field>
      </div>
    </div>
  </div>
</template>

<script>
import BgNumField from "../common/bgNumField.vue";
import NumField from "../common/numField.vue";
export default {
  data() {
    return {
      data: [
        { num: 23298, inc: 243, percent: 1.1 },
        { num: 12247, inc: 639, percent: 5.8 },
        { num: 1282, inc: 221, percent: 18.3 },
      ],
      showData: [],
      addCount: 0,
    };
  },
  components: { BgNumField, NumField },
  props: {},
  watch: {
    data: {},
  },
  mounted() {
    if (this.data) {
      this.resetShowData();
      this.addCount = 0;
    }
    setInterval(() => {
      this.addCount++;
      if (this.addCount >= 20) {
        this.addCount = 0;
        this.resetShowData();
      } else {
        var val = [];
        this.showData.forEach((it) => {
          var add = Math.round(it.inc * Math.random() * 0.05);
          it.num += add;
          it.inc += add;
          it.percent = (it.inc * 100) / it.num;
          val.push(Object.assign({}, it));
        });
        this.showData = val;
      }
    }, 3000);
  },
  methods: {
    resetShowData(){
      var val = [];
      this.data.forEach((it) => {
        val.push(Object.assign({}, it));
      });
      this.showData = val;
    }
  },
};
</script>

<style lang="scss" scoped>
.content {
  color: #244b66;
  line-height: 1;
}

.contentItem {
  padding: 20px;
  color: #697c94;
  font-size: 60px;
  .label {
    margin: 40px 0 30px 0;
  }
}
.city-title {
  padding: 20px;
  color: #697c94;
  font-size: 60px;
  justify-content: space-between;
  div {
    text-align: center;
    width: 400px;
  }
}
.title0 {
  font-size: 75px;
  margin-bottom: 20px;
}
.title1 {
  color: #697c94;
  font-size: 75px;
  margin-bottom: 20px;
}
.title2 {
  width: 350px;
  font-size: 60px;
  margin-right: 40px;
}

.s-label1 {
  margin: 40px 0;
}

.s-label2 {
  color: #244b66;
  margin: 20px 0;
}

.infoblock {
  color: #697c94;
  font-size: 60px;
  display: block;
  width: 450px;
}
.control-box {
  margin-top: 50px;
  padding-top: 50px;
  border: solid #c0ceda;
  border-width: 4px 0 4px 0;
}
.alertImg {
  width: 120px;
  height: 120px;
  margin-right: 20px;
}
.changeBtn {
  width: 450px;
  height: 72px;
  border: solid 4px #697c94;
  margin-right: 20px;
  font-size: 50px;
  line-height: 70px;
  text-align: center;
  cursor: pointer;
  transition: color 1s, background-color 1s;

  &.active {
    background-color: #528be4;
    color: white;
  }
}
</style>
