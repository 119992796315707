<template>
  <div class="contain" :style="extraStyle">
    <div class="line" style="width: 160px"></div>
    <div class="content" :style="{width: `${width}px`}">{{ title }}</div>
    <div class="line" style="width: 100%"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      extraStyle: {
        "flex-direction": "row",
        "background-image": "url(img/sideTitleBg1.png)",
      },
    };
  },
  components: {},
  props: {
    title: { type: String, required: true },
    width:{type:Number, default:600},
    rtl: { type: Boolean, default: false },
  },
  watch: {
    rtl: {
      handler(val) {
        console.log("rtl=", val);
        this.extraStyle["flex-direction"] = val ? "row-reverse" : "row";
        this.extraStyle["background-image"] = val
          ? "url(img/sideTitleBg2.png)"
          : "url(img/sideTitleBg1.png)";
      },
    },
  },
  mounted() {
    this.extraStyle["flex-direction"] = this.rtl ? "row-reverse" : "row";
    this.extraStyle["background-image"] = this.rtl
      ? "url(img/sideTitleBg2.png)"
      : "url(img/sideTitleBg1.png)";
  },
};
</script>

<style lang="scss" scoped>
.contain {
  width: 100%;
  overflow: hidden;
  height: 240px;
  padding-top: 120px;
  display: flex;
  justify-content: flex-start;
  background-size: 100% 160px ;
  background-repeat: no-repeat;
  background-position: bottom;

  div {
    height: 120px;
  }

  .line {
    border-bottom: solid 12px rgba(255, 255, 255, 0.5);
    height: 43px;
  }

  .content {
    flex-shrink: 0;
    font-size: 80px;
    text-align: center;
    color: white;
    line-height: 1;
  }
}
</style>
