<template>
  <div class="content" v-if="data">
    <div class="flex-line">
      <div style="width: 1300px; height: 1100px">
        <div class="title0">重点批发市场全国布局</div>
        <div class="flex-line city-title">
          <div style="text-align: left">城市</div>
          <div>批发市场数量</div>
          <div style="text-align: right">库存数(吨)</div>
        </div>
        <pifa-item
          v-for="(item, idx) in data.citys"
          :active="idx == cityIdx"
          :key="idx"
          :data="item"
        ></pifa-item>
      </div>
      <div class="v-split" style="height: 1100px" />
      <div style="width: 1750px; height: 1100px">
        <div class="flex-line">
          <div style="width: 950px; height: 220px">
            <div class="title1">批发成交额</div>
            <num-field
              :value="saleTotalAmount"
              :numSize="150"
              :digit="2"
              unit="万元"
              :unitSize="70"
            />
          </div>
          <div style="width: 800px; height: 220px">
            <div class="title1">批发成交量</div>
            <num-field
              :value="saleTotalQty"
              :numSize="150"
              unit="万吨"
              :digit="2"
              :unitSize="70"
            />
          </div>
        </div>
        <div class="flex-line" style="margin-top: 80px">
          <div class="flex-line" style="width: 950px; height: 80px">
            <div class="title2">今日实时成交额</div>
            <num-field
              :value="saleTodayAmount"
              :numSize="80"
              unit="万元"
              :digit="2"
              :unitSize="70"
            />
          </div>
          <div class="flex-line" style="width: 800px; height: 80px">
            <div class="title2">今日实时成交量</div>
            <num-field
              :value="saleTodayQty"
              :numSize="80"
              :digit="2"
              unit="吨"
              :unitSize="70"
            />
          </div>
        </div>
        <div
          class="flex-line"
          style="height: 80px; margin-top: 40px; position: relative"
        >
          <div
            class="changeBtn"
            :class="{ active: sumType == 0 }"
            @click="setSumType(0)"
          >
            近三年销售额趋势
          </div>
          <div
            class="changeBtn"
            :class="{ active: sumType == 1 }"
            @click="setSumType(1)"
          >
            今年分月销售趋势
          </div>
          <div
            style="
              position: absolute;
              right: 0;
              color: #697c94;
              font-size: 50px;
            "
          >
            单位 : 万元
          </div>
        </div>
        <Echart
          :options="optionBar"
          id="pifaPlateform"
          width="1770px"
          height="580px"
        ></Echart>
      </div>
    </div>
  </div>
</template>

<script>
import Echart from "@/common/echart";
import NumField from "../common/numField.vue";
import PifaItem from "./pifaItem.vue";
export default {
  data() {
    return {
      incTime: 0,
      incAmountMax: 0,
      incAmountMin: 0,
      price: 4,
      saleTotalAmount: 0,
      saleTotalQty: 0,
      saleTodayAmount: 0,
      saleTodayQty: 0,
      sumType: 0,
      cityIdx: 0,
      optionBar: {
        legend: {
          show: false,
          z: 10,
        },
        grid: {
          top: 120,
          left: 100,
          right: 0,
        },
        xAxis: [
          {
            axisLabel: {
              color: "#697c94",
              fontSize: 40,
            },
            type: "category",
            axisTick: { show: false },
            data: [],
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              color: "#697c94",
              fontSize: 40,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#697c94",
                width: 3,
              },
            },
          },
        ],
        series: [
          {
            type: "line",
            data: [],
            showSymbol: false,
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "#2fafac", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#2fafac00", // 100% 处的颜色
                  },
                ],
              },
            },
            lineStyle: {
              color: "#2fafac",
              width: 10,
            },
          },
        ],
      },
    };
  },
  components: { NumField, Echart, PifaItem },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  watch: {
    data: {
      handler(val) {
        this.refreshChart(val);
        this.setAmountNumber(val);
      },
    },
  },
  mounted() {
    if (this.data) {
      this.refreshChart(this.data);
      this.setAmountNumber(this.data);
    }
    setInterval(() => {
      this.cityIdx++;
      if (this.cityIdx >= this.data.citys.length) {
        this.cityIdx = 0;
      }
    }, 2000);
    setInterval(() => {
      this.swapIdx();
      this.incAmount();
    }, 5000);
  },
  methods: {
    setAmountNumber(val) {
      this.incTime = val.increaseConfig.roundMinute * 12;
      this.incAmountMin = val.increaseConfig.amountIncrease.min / this.incTime;
      this.incAmountMax = val.increaseConfig.amountIncrease.max / this.incTime;
      this.price = val.increaseConfig.weightPrice;
      this.saleTotalAmount = val.saleTotal.amount;
      this.saleTotalQty = val.saleTotal.weight;
      this.saleTodayAmount = val.saleToday.amount;
      this.saleTodayQty = val.saleToday.weight;
    },
    incAmount() {
      if (this.incTime > 0) {
        this.incTime--;
        if (this.incTime > 0) {
          var changeAmt =
            Math.random() * (this.incAmountMax - this.incAmountMin) +
            this.incAmountMin;
          this.saleTotalAmount += changeAmt;
          this.saleTotalQty += changeAmt / this.price;
          this.saleTodayAmount += changeAmt;
          this.saleTodayQty += changeAmt*10000 / this.price;
        } else {
          this.setAmountNumber(this.data);
        }
      }
    },
    swapIdx() {
      this.setSumType(1 - this.sumType);
    },
    setSumType(val) {
      this.sumType = val;
      this.refreshChart(this.data);
    },
    refreshChart(data) {
      var barOpt = Object.assign({}, this.optionBar);
      var barData = this.sumType ? data.monthSum : data.yearSum;
      barOpt.xAxis[0].data = barData.xAxis;
      barOpt.series[0].data = barData.value;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  color: #244b66;
  line-height: 1;
}
.city-title {
  padding: 20px;
  color: #697c94;
  font-size: 60px;
  justify-content: space-between;
  div {
    text-align: center;
    width: 400px;
  }
}
.title0 {
  font-size: 75px;
  margin-bottom: 20px;
}
.title1 {
  color: #697c94;
  font-size: 75px;
  margin-bottom: 20px;
}
.title2 {
  font-size: 60px;
  margin-right: 40px;
}
.changeBtn {
  width: 450px;
  height: 72px;
  border: solid 4px #697c94;
  margin-right: 20px;
  font-size: 50px;
  line-height: 70px;
  text-align: center;
  cursor: pointer;
  transition: color 1s, background-color 1s;

  &.active {
    background-color: #528be4;
    color: white;
  }
}
</style>
