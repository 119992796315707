<template>
  <div class="content">
    <div class="flex-line" style="margin: 80px 240px 0">
      <div class="title0" style="margin-right: 50px">产区分析</div>
      <area-btns
        v-model="areaIdx"
        :list="areas"
        :width="350"
        :height="80"
        :margin="40"
        :changeInterval="3000"
      ></area-btns>
    </div>
    <div class="flex-line">
      <chart-box
        :styleData="{
          width: '1870px',
          height: '860px',
          margin: '40px 0 0 180px',
        }"
      >
        <product-month :data="data" :idx="areaIdx"></product-month>
      </chart-box>
      <chart-box
        :styleData="{
          width: '1400px',
          height: '860px',
          margin: '40px 0 0 60px',
        }"
      >
        <season-product :data="data" :idx="areaIdx"></season-product>
      </chart-box>
    </div>
    <div class="flex-line">
      <chart-box
        :styleData="{
          width: '3330px',
          height: '850px',
          margin: '80px 0 0 180px',
        }"
      >
        <time-product :data="data" :idx="areaIdx"></time-product>
      </chart-box>
    </div>
  </div>
</template>
<script>
import areaBtns from "../common/areaBtns.vue";
import ChartBox from "../chartBox.vue";
import ProductMonth from "./productMonth.vue";
import SeasonProduct from "./seasonProduct.vue";
import TimeProduct from "./timeProduct.vue";
export default {
  data() {
    return {
      areas: [
        "全国总览",
        "西北栽培区",
        "东北栽培区",
        "华北栽培区",
        { name: "长江中下游栽培区", width: 500 },
        "西南栽培区",
        "华南栽培区",
      ],
      areaIdx: 0,
    };
  },
  components: { areaBtns, ChartBox, ProductMonth, SeasonProduct, TimeProduct },
  props: {
    data: { type: Object },
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
.content {
  color: #244b66;
  line-height: 1;
}
.title0 {
  font-size: 75px;
  margin-bottom: 20px;
}
</style>
