<template>
  <div class="flex-line">
    <div class="name">{{ data.name }}</div>
    <div class="barbg">
      <div
        class="bar"
        :style="{
          'background-color': data.color,
          width: (1000 * data.value) / data.max + 'px',
        }"
      ></div>
    </div>
    <num-field :value="data.value" :numSize="80" :digit="data.digit||0" :unit="data.unit||''" :unitSize="40" unitColor="#697c94"></num-field>
  </div>
</template>

<script>
import NumField from './numField.vue';
export default {
  data() {
    return {};
  },
  components: {
    NumField
  },
  props: {
    data: { type: Object, required: true },
  },
  watch: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
.flex-line {
  margin: 25px 0;
  align-items: baseline;
  color: #244b66;
  font-size: 60px;
}
.idxNumOut {
  width: 90px;
  height: 90px;
  border-radius: 100%;
  border: 2px solid white;
  background: transparent;
  padding: 10px;
  margin: 0 20px;
  .idxNum {
    width: 70px;
    height: 70px;
    font-size: 50px;
    border-radius: 100%;
    color: white;
    line-height: 70px;
    text-align: center;
  }
}
.name {
  width: 200px;
  height: 100%;
  text-align: right;
  margin: 0 20px;
}
.barbg {
  margin: 0 20px;
  width: 1000px;
  height: 32px;
  background-color: #bdccd8;

  .bar {
    height: 32px;
  }
}
.value {
  width: 250px;
  text-align: right;
}
</style>
