<template>
  <div class="content" style="position: relative">
    <div class="flex-line">
      <div class="title1">入库信息概览</div>
    </div>
    <div class="flex-line">
      <div
        style="
          position: absolute;
          text-align: center;
          width: 440px;
          top: 350px;
          left: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
        "
      >
        <num-field :value="showData.total" :numSize="120"></num-field>
        <div style="font-size: 55px; color: #697c94">总数（吨）</div>
      </div>
      <Echart :options="chartOption1" width="440px" height="750px"></Echart>
      <Echart :options="chartOption2" width="730px" height="750px"></Echart>
      <Echart :options="chartOption3" width="730px" height="750px"></Echart>
    </div>
  </div>
</template>

<script>
import Echart from "@/common/echart";
import NumField from "../common/numField.vue";
const dayP = 24 * 60 * 60000;
const color2 = [
  {
    type: "linear",
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [
      {
        offset: 0,
        color: "#46cdd0", // 0% 处的颜色
      },
      {
        offset: 1,
        color: "#32adaa", // 100% 处的颜色
      },
    ],
  },
];
export default {
  data() {
    return {
      curIdx: 0,
      chartOption1: {
        title: {
          show: false,
          text: "质检总数与百分比",
          left: "center",
          top: "bottom",
          textStyle: {
            fontWeight: "normal",
            color: "#697c94",
            fontSize: 55,
          },
        },
        grid: {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        },
        legend: {
          data: ["已入库", "待入库"],
          show: true,
          z: 10,
          top: "bottom",
          left: "center",
          orient: "vertical",
          padding: 10,
          itemGap: 30,
          itemWidth: 40,
          itemHeight: 25,
          textStyle: {
            color: "#697c94",
            fontSize: 55,
          },
        },
        toolbox: {
          show: false,
        },
        series: [
          {
            label: {
              show: false,
              formatter: ["{title|{b}}{abg|}", "{value|{c}%}"].join("\n"),
              rich: {
                title: {
                  color: "#244b66",
                  align: "center",
                  height: 100,
                  fontSize: 50,
                },
                value: {
                  color: "#244b66",
                  align: "center",
                  fontSize: 75,
                },
              },
              alignTo: "labelLine",
            },
            labelLine: {
              show: false,
              length: 50,
              length2: 50,
              lineStyle: {
                color: "#244b66",
                width: 5,
              },
            },
            type: "pie",
            radius: ["80%", "100%"],
            center: ["50%", "45%"],
            //roseType: "radius",
            color: ["#697c94", "#f18b4d"],
            data: [
              { value: 30, name: "待入库" },
              { value: 70, name: "已入库" },
            ],
          },
        ],
      },
      chartOption2: {
        color: color2,
        title: {
          text: "近5天入库量趋势",
          left: "center",
          top: "bottom",
          textStyle: {
            fontWeight: "normal",
            color: "#697c94",
            fontSize: 55,
          },
        },
        tooltip: {
          show: false,
        },
        grid: {
          top: 200,
          left: 130,
          right: 30,
          bottom: 150,
        },
        legend: {
          show: false,
        },
        xAxis: [
          {
            type: "category",
            axisTick: {
              alignWithLabel: true,
            },
            axisLabel: {
              color: "#697c94",
              fontSize: 35,
            },
            // prettier-ignore
            data: ['5/16','5/17','5/18','5/19','5/20'],
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "单位：吨",
            nameTextStyle: {
              color: "#697c94",
              fontSize: 40,
            },
            nameGap: 40,
            position: "left",
            alignTicks: true,
            axisLine: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#697c94",
                width: 3,
              },
            },
            axisLabel: {
              color: "#32adaa",
              fontSize: 35,
            },
          },
        ],
        series: [
          {
            type: "bar",
            barWidth: 26,
            data: [2645, 2234, 3654, 2755, 3124],
          },
        ],
      },
      chartOption3: {
        color: ["#32adaa"],
        title: {
          text: "24小时入库量趋势",
          left: "center",
          top: "bottom",
          textStyle: {
            fontWeight: "normal",
            color: "#697c94",
            fontSize: 55,
          },
        },
        tooltip: {
          show: false,
        },
        grid: {
          top: 200,
          left: 30,
          right: 130,
          bottom: 150,
        },
        legend: {
          show: false,
        },
        xAxis: [
          {
            type: "category",
            axisTick: {
              alignWithLabel: true,
            },
            axisLabel: {
              color: "#697c94",
              fontSize: 35,
            },
            // prettier-ignore
            data: ['0时'],
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "单位：吨",
            nameTextStyle: {
              color: "#697c94",
              fontSize: 40,
            },
            nameGap: 40,
            position: "right",
            alignTicks: true,
            axisLine: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#697c94",
                width: 3,
              },
            },
            axisLabel: {
              color: "#32adaa",
              fontSize: 35,
            },
          },
        ],
        series: [
          {
            type: "line",
            lineStyle: {
              width: 5,
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "#2CAEAB", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "#2CAEAB00", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            itemStyle: {
              borderColor: "white",
              borderWidth: 4,
            },
            symbol: "circle",
            symbolSize: 26,
            data: [12312],
          },
        ],
      },
      data: { total: 3600, inPercent: 70 },
      showData: {},
    };
  },
  components: { Echart, NumField },
  props: {},
  watch: {
    data: {
      handler(val) {
        this.oriData = Object.assign({}, this.data);
        this.refreshChart(val, this.curIdx);
      },
    },
  },
  mounted() {
    this.oriData = Object.assign({}, this.data);
    this.refreshChart(this.data, this.curIdx);
    setInterval(() => {
      var data = Object.assign({}, this.oriData);
      data.total *= 0.9 + Math.random() * 0.2;
      data.inPercent *= 0.8 + Math.random() * 0.4;
      this.refreshChart(data, this.curIdx);
    }, 5000);
  },
  methods: {
    refreshChart(data, idx) {
      this.showData = data;
      console.log(data, idx);
      var opt1 = Object.assign({}, this.chartOption1);
      opt1.series[0].data[0].value = 100 - data.inPercent;
      opt1.series[0].data[1].value = data.inPercent;
      var opt2 = Object.assign({}, this.chartOption2);
      var d = new Date();
      var drr = [];
      var val2 = [];
      for (var i = 5; i > 0; i--) {
        var td = new Date(d - dayP * i);
        drr.push(`${td.getMonth() + 1}/${td.getDay()}`);
        val2.push(1000 + 2500 * Math.random());
      }
      opt2.xAxis[0].data = drr;
      opt2.series[0].data = val2;
      this.chartOption2 = opt2;
      var opt3 = Object.assign({}, this.chartOption3);
      var hrr = [];
      var val3 = [];
      for (i = 0; i < 24; i++) {
        hrr.push(i + "时");
        val3.push(100 + 400 * Math.random());
      }
      opt3.xAxis[0].data = hrr;
      opt3.series[0].data = val3;
      this.chartOption3 = opt3;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  color: #244b66;
  line-height: 1;
}
.title1 {
  font-size: 75px;
  margin-bottom: 20px;
}
.changeBtn {
  width: 300px;
  height: 72px;
  border: solid 4px #697c94;
  margin-right: 20px;
  font-size: 50px;
  line-height: 70px;
  text-align: center;
  cursor: pointer;
  transition: color 1s, background-color 1s;

  &.active {
    background-color: #528be4;
    color: white;
  }
}
</style>
