<template>
  <div class="content">
    <div class="flex-line">
      <div style="width: 1450px; height: 900px">
        <div class="title0">物流构成</div>
        <Echart :options="chartOption" width="100%" height="800px"></Echart>
      </div>
      <div class="v-split" style="height: 900px; margin-right: 100px" />
      <div style="width: 1600px; height: 900px">
        <div class="title0">物流运输实时动态</div>
        <div class="flex-line">
          <div style="color: #697c94; font-size: 60px; width: 700px">
            <div class="s-label1">货运量</div>
            <num-field
              :value="gettotal(showData.qty)"
              :digit="2"
              :numSize="130"
              numColor="#244b66"
              unit="万吨"
              :unitSize="60"
            ></num-field>
            <div class="s-label1">金额</div>
            <num-field
              :value="showData.amount"
              :digit="2"
              :numSize="130"
              numColor="#244b66"
              unit="亿元"
              :unitSize="60"
            ></num-field>
            <div class="s-label1">运输总成本</div>
            <num-field
              :value="showData.cost"
              :digit="2"
              :numSize="130"
              numColor="#244b66"
              unit="万元"
              :unitSize="60"
            ></num-field>
          </div>
          <div>
            <div class="flex-line">
              <div class="infoblock">
                <div><img src="img/c1.png" /></div>
                <div class="s-label2">在装</div>
                <num-field
                  :value="showData.qty[0]"
                  :numSize="110"
                  :digit="1"
                  numColor="#f08a4c"
                  unit="万吨"
                  :unitSize="60"
                ></num-field>
              </div>
              <div class="infoblock">
                <div><img src="img/c2.png" /></div>
                <div class="s-label2">在途</div>
                <num-field
                  :value="showData.qty[1]"
                  :numSize="110"
                  :digit="1"
                  numColor="#36a59f"
                  unit="万吨"
                  :unitSize="60"
                ></num-field>
              </div>
            </div>
            <div class="flex-line" style="margin-top: 40px">
              <div class="infoblock">
                <div><img src="img/c3.png" /></div>
                <div class="s-label2">在卸</div>
                <num-field
                  :value="showData.qty[2]"
                  :numSize="110"
                  :digit="1"
                  numColor="#528be4"
                  unit="万吨"
                  :unitSize="60"
                ></num-field>
              </div>
              <div class="infoblock">
                <div><img src="img/c4.png" /></div>
                <div class="s-label2">完成</div>
                <num-field
                  :value="showData.qty[3]"
                  :numSize="110"
                  :digit="1"
                  numColor="#e86366"
                  unit="万吨"
                  :unitSize="60"
                ></num-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Echart from "@/common/echart";
import NumField from "../common/numField.vue";
const colors = ["#F18B4D", "#2EAFAB", "#528BE4", "#F56363", "#6A7C94"];
export default {
  data() {
    return {
      cctvList: [],
      cctvIdx: 0,
      chartOption: {
        color: colors,
        grid: {
          top: 200,
          left: 100,
          right: 100,
          bottom: 50,
        },
        legend: {
          data: ["公路", "铁路", "航空", "水路", "其他"],
          show: true,
          z: 10,
          top: 10,
          left: "left",
          padding: 50,
          itemGap: 100,
          itemWidth: 50,
          itemHeight: 30,
          textStyle: {
            color: "#697c94",
            fontSize: 55,
          },
        },
        toolbox: {
          show: false,
        },
        series: [
          {
            startAngle: 180,
            endAngle: 360,
            label: {
              formatter: ["{title|{b}}{abg|}", "{value|{c}%}"].join("\n"),
              rich: {
                title: {
                  color: "#244b66",
                  align: "center",
                  height: 100,
                  fontSize: 50,
                },
                value: {
                  color: "#244b66",
                  align: "center",
                  fontSize: 75,
                },
              },
              alignTo: "labelLine",
            },
            labelLine: {
              length: 50,
              length2: 50,
              lineStyle: {
                color: "#244b66",
                width: 5,
              },
            },
            type: "pie",
            radius: ["70%", "100%"],
            center: ["50%", "90%"],
            //roseType: "radius",
            data: [
              { value: 20, name: "公路" },
              { value: 40, name: "铁路" },
              { value: 12, name: "航空" },
              { value: 20, name: "水路" },
              { value: 8, name: "其他" },
              {
                value: 100,
                itemStyle: {
                  // stop the chart from rendering this piece
                  color: "none",
                  decal: {
                    symbol: "none",
                  },
                },
                labelLine: { show: false },
                label: {
                  show: false,
                },
              },
            ],
          },
        ],
      },
      data: {
        wayPercent: [20, 40, 12, 20, 8],
        qty: [31.5, 24, 11, 47.3],
        amount: 356.32,
        cost: 862.32,
      },
      showData: {},
      addCount: 0,
    };
  },
  components: { NumField, Echart },
  props: {
    // data: {
    //   type: Object,
    // },
  },
  watch: {
    data: {
      handler(val) {
        this.oriData = Object.assign({}, val);
        this.addCount = 0;
        this.refreshChart(val);
      },
    },
  },
  mounted() {
    if (this.data) {
      this.oriData = Object.assign({}, this.data);
      this.refreshChart(this.data);
      this.addCount = 0;
    }
    setInterval(() => {
      this.addCount++;
      var data = Object.assign({}, this.showData);
      if (this.addCount >= 20) {
        data = Object.assign({}, this.oriData);
        data.qty = Object.assign([], this.data.qty);
        this.addCount = 0;
      }
      data.wayPercent = Object.assign([], this.data.wayPercent);
      console.log(this.addCount);
      var t = 0;
      for (var i = 0; i < 5; i++) {
        data.wayPercent[i] *= 0.8 + Math.random() * 0.4;
        t += data.wayPercent[i];
      }
      var p = 100;
      for (i = 0; i < 4; i++) {
        p -= data.wayPercent[i] = ((data.wayPercent[i] * 100) / t).toFixed(1);
      }
      data.wayPercent[4] = p.toFixed(1);
      for (i = 0; i < 4; i++) {
        data.qty[i] *= 1.005 + Math.random() * 0.005;
      }
      data.amount *= 1.005 + Math.random() * 0.005;
      data.cost *= 1.005 + Math.random() * 0.005;
      this.refreshChart(data);
    }, 3000);
  },
  methods: {
    refreshChart(data) {
      this.showData = data;
      var opt = Object.assign({}, this.chartOption);
      for (var i = 0; i < 5; i++) {
        opt.series[0].data[i].value = data.wayPercent[i];
      }
      this.chartOption = opt;
    },
    gettotal(arr) {
      var t = 0;
      arr.forEach((element) => {
        t += element;
      });
      return t;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  color: #244b66;
  line-height: 1;
}
.city-title {
  padding: 20px;
  color: #697c94;
  font-size: 60px;
  justify-content: space-between;
  div {
    text-align: center;
    width: 400px;
  }
}
.title0 {
  font-size: 75px;
  margin-bottom: 20px;
}
.title1 {
  color: #697c94;
  font-size: 75px;
  margin-bottom: 20px;
}
.title2 {
  width: 350px;
  font-size: 60px;
  margin-right: 40px;
}

.s-label1 {
  margin: 40px 0;
}

.s-label2 {
  color: #244b66;
  margin: 20px 0;
}

.infoblock {
  color: #697c94;
  font-size: 60px;
  display: block;
  width: 450px;
}
.control-box {
  margin-top: 50px;
  padding-top: 50px;
  border: solid #c0ceda;
  border-width: 4px 0 4px 0;
}
.alertImg {
  width: 120px;
  height: 120px;
  margin-right: 20px;
}
.changeBtn {
  width: 450px;
  height: 72px;
  border: solid 4px #697c94;
  margin-right: 20px;
  font-size: 50px;
  line-height: 70px;
  text-align: center;
  cursor: pointer;
  transition: color 1s, background-color 1s;

  &.active {
    background-color: #528be4;
    color: white;
  }
}
</style>
