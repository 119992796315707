<template>
  <div class="content" v-if="data">
    <div class="flex-line" style="position: relative">
      <div class="title1">土壤数据分析</div>
    </div>
    <env-info-item v-for="item in list" :key="item.idx" :data="item"></env-info-item>
  </div>
</template>

<script>
import EnvInfoItem from '../common/envInfoItem.vue';
const colors = ["#F18B4D", "#528BE4", "#6673D8", "#697c94"];
export default {
  data() {
    return { curIdx: 0 };
  },
  components: {EnvInfoItem  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  computed: {
    list() {
      return this.data[this.curIdx];
    },
  },
  watch: {},
  mounted() {
    setInterval(() => {
      this.changeIdx();
    }, 3000);
  },
  methods: {
    changeIdx() {
      this.curIdx++;
      if (this.curIdx >= 3) {
        this.curIdx = 0;
      }
      this.list.forEach((item, idx) => {
        item.idx = idx + 1;
        item.color =
          colors.length > idx ? colors[idx] : colors[colors.length - 1];
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  color: #244b66;
  line-height: 1;
}
.title1 {
  font-size: 75px;
  margin-bottom: 20px;
}
</style>
