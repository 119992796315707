<template>
  <div class="flex-line pifa-item" :class="{ active }">
    <div>· {{ data.time }}</div>
    <div>{{ data.warehouse }}</div>
    <div style="flex: 2;">{{ data.orderNo }}</div>
    <div>运输中</div>
    <div>{{ data.prodName }}</div>
    <div>{{ data.qty }}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  props: {
    data: { type: Object, required: true },
    active: { type: Boolean, default: false },
  },
  watch: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
.flex-line {
  margin: 35px 0 0;
  align-items: baseline;
  justify-content: space-between;
}

.pifa-item {
  padding: 20px;
  color: #7a8ca2;
  font-size: 60px;
  background-color: #c2d2df;
  transition: color 1s, background-color 1s;

  div{
    text-align: center;
    flex:1
  }

  &.active {
    color: white;
    background-color: #2fafac;
  }
}
</style>
