// 屏幕适配 mixin 函数

// * 默认缩放值
const scale = {
  width: "1",
  height: "1",
};

// * 设计稿尺寸（px）
const baseHeight = 3536;

export default {
  data() {
    return {
      // * 定时函数
      drawTiming: null,
    };
  },
  mounted() {
    this.calcRate();
    window.addEventListener("resize", this.resize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resize);
  },
  methods: {
    calcRate() {
      const appRef = this.$refs["appRef"];
      if (!appRef) return;
      // 当前宽高比
      if (appRef) {
        scale.height = (window.innerHeight / baseHeight).toFixed(5);
        appRef.style.transform = `scale(${scale.height}, ${scale.height}) translate(-50%, -50%)`;
        appRef.style.width = window.innerWidth / scale.height + "px";
      }
    },
    resize() {
      clearTimeout(this.drawTiming);
      this.drawTiming = setTimeout(() => {
        this.calcRate();
      }, 200);
    },
  },
};
