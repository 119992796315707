<template>
  <div class="content">
    <div class="flex-line" style="position: relative">
      <div class="title1">各品牌销售占比与排行</div>
    </div>
    <div class="flex-line" style="flex-wrap: wrap">
      <brand-item
        class="brandItem"
        v-for="item in list"
        :key="item.idx"
        :data="item"
      ></brand-item>
    </div>
  </div>
</template>

<script>
import BrandItem from "../common/brandItem.vue";
export default {
  data() {
    return { curIdx: 0 };
  },
  components: { BrandItem },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  watch: {},
  computed: {
    list() {
      return this.data[this.curIdx];
    },
  },
  mounted() {
    setInterval(() => {
      this.changeIdx();
    }, 5000);
  },
  methods: {
    changeIdx() {
      this.curIdx++;
      if (this.curIdx >= 3) {
        this.curIdx = 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  color: #244b66;
  line-height: 1;
}
.brandItem {
  margin: 0 40px;
}
.title1 {
  font-size: 75px;
  margin-bottom: 20px;
}
</style>
