import { render, staticRenderFns } from "./provincePlantItem.vue?vue&type=template&id=6a15e898&scoped=true&"
import script from "./provincePlantItem.vue?vue&type=script&lang=js&"
export * from "./provincePlantItem.vue?vue&type=script&lang=js&"
import style0 from "./provincePlantItem.vue?vue&type=style&index=0&id=6a15e898&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a15e898",
  null
  
)

export default component.exports