<template>
  <div class="box">
    <img class="fill" src="/img/bar.webp" :style="`transform: scale(1.35,${value/100});`" />
  </div>
</template>
<script>
export default {
  props: {
    value: { type: Number },
  },
};
</script>
<style lang="scss" scoped>
.box {
  width: 167px;
  height: 237px;
  border: solid 4px #b6c2ce;
  position: relative;
  .fill {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    transform-origin: 0% 100%;
  }
}
</style>
