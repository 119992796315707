<template>
  <div class="content">
    <div class="flex-line" style="position: relative">
      <div class="title1">车辆类型分布</div>
    </div>
    <div class="flex-line" style="flex-wrap: wrap">
      <vehicle-type-item
        class="brandItem"
        v-for="item in list"
        :key="item.idx"
        :data="item"
      ></vehicle-type-item>
    </div>
  </div>
</template>

<script>
import vehicleTypeItem from "./vehicleTypeItem.vue";
const colors = [ "#2EAFAB","#F18B4D",  "#F56363", "#528BE4","#6A7C94"];
export default {
  components: { vehicleTypeItem },
  data() {
    return { curIdx: 0, list: [
      {
        "name": "货车",
        "value": 1570,
        "percent": 45,
        color:colors[0],
      },
      {
        "name": "冷藏车",
        "value": 480,
        "percent": 25,
        color:colors[1],
      },
      {
        "name": "集装箱车",
        "value": 342,
        "percent": 15,
        color:colors[2],
      },
      {
        "name": "其他",
        "value": 108,
        "percent": 5,
        color:colors[3],
      }
    ] };
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  watch: {},
  mounted() {
    setInterval(() => {
      this.changeIdx();
    }, 5000);
  },
  methods: {
    changeIdx() {
      this.curIdx++;
      if (this.curIdx >= 3) {
        this.curIdx = 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  color: #244b66;
  line-height: 1;
}
.brandItem {
  margin: 40px 25px 40px 0;
}
.title1 {
  font-size: 75px;
  margin-bottom: 20px;
}
</style>
