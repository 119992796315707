<template>
  <div id="index" ref="appRef">
    <div v-if="!curPageIdx" class="first-page">
      请选择当前页面显示分屏号
      <div>
        销售屏：
        <button @click="showScreen(1)">屏幕1</button>
        <button @click="showScreen(2)">屏幕2</button>
        <button @click="showScreen(3)">屏幕3</button>
      </div>
      <div>
        种植屏：
        <button @click="showScreen(11)">屏幕1</button>
        <button @click="showScreen(12)">屏幕2</button>
        <button @click="showScreen(13)">屏幕3</button>
      </div>
      <div>
        仓储屏：
        <button @click="showScreen(21)">屏幕1</button>
        <button @click="showScreen(22)">屏幕2</button>
        <button @click="showScreen(23)">屏幕3</button>
      </div>
    </div>
    <div v-else class="bg" :class="`bg${curPageIdx % 10}`">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <!-- 销售屏 -->
        <div v-if="curPageIdx == 1">
          <side-title title="￮ 线上零售"></side-title>
          <div class="flex-line">
            <chart-box
              :styleData="{
                width: '3340px',
                height: '1230px',
                margin: '80px 0 0 180px',
              }"
            >
              <sale-summary :data="sales.saleSummaryData"></sale-summary>
            </chart-box>
          </div>
          <div class="flex-line">
            <chart-box
              :styleData="{
                width: '1880px',
                height: '860px',
                margin: '80px 0 0 180px',
              }"
            >
              <supplyer-ladder
                :data="sales.supplyerLadderData"
              ></supplyer-ladder>
            </chart-box>
            <chart-box
              :styleData="{
                width: '1400px',
                height: '860px',
                margin: '80px 0 0 60px',
              }"
            >
              <pingjia-radar :data="sales.pingjiaRadarData"></pingjia-radar>
            </chart-box>
          </div>
          <div class="flex-line">
            <chart-box
              :styleData="{
                width: '3340px',
                height: '860px',
                margin: '80px 0 0 180px',
              }"
            >
              <brand-ladder :data="sales.brandLadderData"></brand-ladder>
            </chart-box>
          </div>
        </div>
        <div v-if="curPageIdx == 2">
          <center-title
            :title="title"
            style="position: relative; z-index: 10"
          ></center-title>
          <div
            style="position: absolute; top: 350px; bottom: 0; left: 0; right: 0"
          >
            <map-view
              :data="sales.mapSummaryData"
              :mapData="mapData"
            ></map-view>
          </div>
        </div>
        <div v-if="curPageIdx == 3">
          <side-title title="线下批发 ￮" :rtl="true"></side-title>
          <div class="flex-line right">
            <chart-box
              :styleData="{
                width: '3330px',
                height: '1230px',
                margin: '80px 180px 0 0',
              }"
            >
              <pifa-summary :data="sales.pifaSummaryData"></pifa-summary>
            </chart-box>
          </div>
          <div class="flex-line right">
            <chart-box
              :styleData="{
                width: '1870px',
                height: '860px',
                margin: '80px 180px 0 0 ',
              }"
            >
              <stock-turn :data="sales.stockTurnData"></stock-turn>
            </chart-box>
            <chart-box
              :styleData="{
                width: '1400px',
                height: '860px',
                margin: '80px 60px 0 0',
              }"
              ><stock-qty :data="sales.stockQtyData"></stock-qty
            ></chart-box>
          </div>
          <div class="flex-line right">
            <chart-box
              :styleData="{
                width: '3330px',
                height: '860px',
                margin: '80px 180px 0 0',
              }"
              ><area-price :data="sales.areaPriceData"></area-price
            ></chart-box>
          </div>
        </div>
        <!-- 种植屏 -->
        <div v-if="curPageIdx == 11">
          <side-title title="￮ 生产/种植/产地分析" :width="1000"></side-title>
          <div class="flex-line">
            <chart-box
              :styleData="{
                width: '3330px',
                height: '1130px',
                margin: '80px 0 0 180px',
              }"
            >
              <province-plant :data="plant.privincePlantData"></province-plant>
            </chart-box>
          </div>
          <product-area :data="plant.productAreaData"></product-area>
        </div>
        <div v-if="curPageIdx == 12">
          <center-title
            :title="title"
            style="position: relative; z-index: 10"
          ></center-title>
          <div
            style="position: absolute; top: 350px; bottom: 0; left: 0; right: 0"
          >
            <plant-map-view :data="plant.mapData"></plant-map-view>
          </div>
        </div>
        <div v-if="curPageIdx == 13">
          <side-title
            title="哈密瓜标准化示范基地 ￮"
            :width="1000"
            :rtl="true"
          ></side-title>
          <div class="flex-line right">
            <chart-box
              :styleData="{
                width: '3330px',
                height: '1230px',
                margin: '80px 180px 0 0',
              }"
            >
              <base-summary :data="plant.prodBaseData"></base-summary>
            </chart-box>
          </div>
          <div class="flex-line right">
            <chart-box
              :styleData="{
                width: '1870px',
                height: '860px',
                margin: '80px 180px 0 0 ',
              }"
            >
              <enviroment-analize
                :data="plant.envAnlyData"
              ></enviroment-analize>
            </chart-box>
            <chart-box
              :styleData="{
                width: '1400px',
                height: '860px',
                margin: '80px 60px 0 0',
              }"
            >
              <smart-warning :data="plant.smartWarningData"></smart-warning>
            </chart-box>
          </div>
          <div class="flex-line right">
            <chart-box
              :styleData="{
                width: '3330px',
                height: '860px',
                margin: '80px 180px 0 0',
              }"
            >
              <iot-device :data="plant.iotDeviceData"></iot-device>
            </chart-box>
          </div>
        </div>
        <!-- 仓储屏 -->
        <div v-if="curPageIdx == 21">
          <side-title title="￮ 仓储" :width="400"></side-title>
          <div class="flex-line">
            <chart-box
              :styleData="{
                width: '3360px',
                height: '1030px',
                margin: '80px 0 0 180px',
              }"
            >
              <stock-base-summary
                :data="stock.stockBaseData"
              ></stock-base-summary>
            </chart-box>
          </div>
          <div class="flex-line">
            <chart-box
              :styleData="{
                width: '3360px',
                height: '930px',
                margin: '80px 0 0 180px',
              }"
            >
              <inout-task></inout-task>
            </chart-box>
          </div>
          <div class="flex-line">
            <chart-box
              :styleData="{
                width: '1200px',
                height: '970px',
                margin: '80px 0 0 180px',
              }"
            >
              <check-report></check-report>
            </chart-box>
            <chart-box
              :styleData="{
                width: '2100px',
                height: '970px',
                margin: '80px 0 0 60px',
              }"
            >
              <in-stock-report></in-stock-report>
            </chart-box>
          </div>
        </div>
        <div v-if="curPageIdx == 22">
          <center-title
            :title="title"
            style="position: relative; z-index: 10"
          ></center-title>
          <div
            style="position: absolute; top: 350px; bottom: 0; left: 0; right: 0"
          >
            <stock-map-view :data="stock.mapData"></stock-map-view>
          </div>
        </div>
        <div v-if="curPageIdx == 23">
          <side-title title="运输 ￮" :width="400" :rtl="true"></side-title>
          <div class="flex-line right">
            <chart-box
              :styleData="{
                width: '3340px',
                height: '1020px',
                margin: '80px 180px 0 0',
              }"
            >
              <ship-summary></ship-summary>
            </chart-box>
          </div>
          <div class="flex-line right">
            <chart-box
              :styleData="{
                width: '1170px',
                height: '930px',
                margin: '80px 180px 0 0 ',
              }"
            >
              <ship-hour></ship-hour>
            </chart-box>
            <chart-box
              :styleData="{
                width: '2100px',
                height: '930px',
                margin: '80px 60px 0 0',
              }"
            >
              <ship-time></ship-time>
            </chart-box>
          </div>
          <div class="flex-line right">
            <chart-box
              :styleData="{
                width: '2050px',
                height: '970px',
                margin: '80px 180px 0 0',
              }"
            >
              <transport-summary></transport-summary>
            </chart-box>
            <chart-box
              :styleData="{
                width: '1220px',
                height: '970px',
                margin: '80px 60px 0 0',
              }"
            >
              <vehicle-type></vehicle-type>
            </chart-box>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatTime } from "../utils/index.js";
import ChartBox from "../components/chartBox.vue";
import SideTitle from "../components/sideTitle.vue";
import CenterTitle from "../components/centerTitle.vue";
import drawMixin from "../utils/drawMixin";
import PifaSummary from "../components/saleData/pifaSummary.vue";
import SaleSummary from "../components/saleData/saleSummary.vue";
import SupplyerLadder from "../components/saleData/supplyerLadder.vue";
import BrandLadder from "../components/saleData/brandLadder.vue";
import PingjiaRadar from "../components/saleData/pingjiaRadar.vue";
import StockTurn from "../components/saleData/stockTurn.vue";
import StockQty from "../components/saleData/stockQty.vue";
import AreaPrice from "../components/saleData/areaPrice.vue";
import MapView from "../components/saleData/mapView.vue";
import ProvincePlant from "../components/plantData/provincePlant.vue";
import ProductArea from "../components/plantData/productArea.vue";
import EnviromentAnalize from "../components/plantData/enviromentAnalize.vue";
import SmartWarning from "../components/plantData/smartWarning.vue";
import IotDevice from "../components/plantData/iotDevice.vue";
import BaseSummary from "../components/plantData/baseSummary.vue";
import PlantMapView from "../components/plantData/plantMapView.vue";
import StockBaseSummary from "../components/stockData/stockBaseSummary.vue";
import InoutTask from "../components/stockData/inoutTask.vue";
import CheckReport from "../components/stockData/checkReport.vue";
import InStockReport from "../components/stockData/instockReport.vue";
import ShipSummary from "../components/stockData/shipSummary.vue";
import ShipTime from "../components/stockData/shipTime.vue";
import ShipHour from "../components/stockData/shipHour.vue";
import VehicleType from "../components/stockData/vehicleType.vue";
import TransportSummary from "../components/stockData/transportSummary.vue";
import StockMapView from "../components/stockData/stockMapView.vue";

//const apiUrl = "http://localhost:5000/";
const apiUrl = "data/";
const colors = ["#2fafac", "#F18B4D", "#6497E6", "#697c94"];
export default {
  mixins: [drawMixin],
  data() {
    return {
      curPageIdx: 0,
      title: "哈密瓜产业大数据平台",
      timing: null,
      dataTiming: null,
      loading: false,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      sales: {
        saleSummaryData: null,
        pifaSummaryData: null,
        stockTurnData: null,
        stockQtyData: null,
        areaPriceData: null,
        mapSummaryData: null,
        pingjiaRadarData: [],
        supplyerLadderData: [],
        brandLadderData: [],
      },
      plant: {
        privincePlantData: null,
        productAreaData: null,
        envAnlyData: null,
        smartWarningData: null,
        prodBaseData: null,
        mapData: null,
      },
      stock: {
        stockBaseData: null,
        mapData: null,
      },
    };
  },
  components: {
    SideTitle,
    CenterTitle,
    ChartBox,
    SaleSummary,
    SupplyerLadder,
    BrandLadder,
    PingjiaRadar,
    PifaSummary,
    StockTurn,
    StockQty,
    AreaPrice,
    MapView,
    ProvincePlant,
    ProductArea,
    EnviromentAnalize,
    SmartWarning,
    IotDevice,
    BaseSummary,
    PlantMapView,
    StockBaseSummary,
    InoutTask,
    CheckReport,
    InStockReport,
    ShipSummary,
    ShipTime,
    ShipHour,
    VehicleType,
    TransportSummary,
    StockMapView,
  },
  mounted() {
    if (this.$route.query.p) {
      this.curPageIdx = this.$route.query.p;
    }
    this.getSalesData();
    this.getPlantData();
    this.getStockData();
    this.timeFn();
    this.cancelLoading();
  },
  beforeDestroy() {
    clearInterval(this.timing);
  },
  methods: {
    getPlantData() {
      this.$http.get(apiUrl + "plant/provincePlant.json").then((res) => {
        if (res.data.code == 200) {
          this.plant.privincePlantData = res.data.data;
        }
      });
      this.$http.get(apiUrl + "plant/productArea.json").then((res) => {
        if (res.data.code == 200) {
          this.plant.productAreaData = res.data.data;
        }
      });
      this.$http.get(apiUrl + "plant/envAnly.json").then((res) => {
        if (res.data.code == 200) {
          this.plant.envAnlyData = res.data.data;
        }
      });
      this.$http.get(apiUrl + "plant/smartWarning.json").then((res) => {
        if (res.data.code == 200) {
          this.plant.smartWarningData = res.data.data;
        }
      });
      this.$http.get(apiUrl + "plant/iotDevice.json").then((res) => {
        if (res.data.code == 200) {
          this.plant.iotDeviceData = res.data.data;
        }
      });
      this.$http.get(apiUrl + "plant/prodBaseData.json").then((res) => {
        if (res.data.code == 200) {
          this.plant.prodBaseData = res.data.data;
        }
      });
      this.$http.get(apiUrl + "plant/plantMap.json").then((res) => {
        if (res.data.code == 200) {
          this.plant.mapData = res.data.data;
        }
      });
    },
    getSalesData() {
      this.$http.get(apiUrl + "sales/saleSummary.json").then((res) => {
        if (res.data.code == 200) {
          this.sales.saleSummaryData = res.data.data;
        }
      });
      this.$http.get(apiUrl + "sales/pifaSummary.json").then((res) => {
        if (res.data.code == 200) {
          this.sales.pifaSummaryData = res.data.data;
        }
      });
      this.$http.get(apiUrl + "sales/supplierLadder.json").then((res) => {
        if (res.data.code == 200) {
          this.sales.supplyerLadderData = res.data.data;
        }
      });
      this.$http.get(apiUrl + "sales/brandLadder.json").then((res) => {
        if (res.data.code == 200) {
          res.data.data.forEach((list) => {
            list.forEach((item, idx) => {
              item.idx = idx + 1;
              item.color =
                colors.length > idx ? colors[idx] : colors[colors.length - 1];
            });
          });
          this.sales.brandLadderData = res.data.data;
        }
      });
      this.$http.get(apiUrl + "sales/pingjia.json").then((res) => {
        if (res.data.code == 200) {
          res.data.data.forEach((item, idx) => {
            item.color =
              colors.length > idx ? colors[idx] : colors[colors.length - 1];
          });
          this.sales.pingjiaRadarData = res.data.data;
        }
      });
      this.$http.get(apiUrl + "sales/stockTurn.json").then((res) => {
        if (res.data.code == 200) {
          this.sales.stockTurnData = res.data.data;
        }
      });
      this.$http.get(apiUrl + "sales/stockQty.json").then((res) => {
        if (res.data.code == 200) {
          this.sales.stockQtyData = res.data.data;
        }
      });
      this.$http.get(apiUrl + "sales/areaPrice.json").then((res) => {
        if (res.data.code == 200) {
          this.sales.areaPriceData = res.data.data;
        }
      });
      this.$http.get(apiUrl + "sales/mapSummary.json").then((res) => {
        if (res.data.code == 200) {
          this.sales.mapSummaryData = res.data.data;
        }
      });
    },
    getStockData() {
      this.$http.get(apiUrl + "stock/stockBaseData.json").then((res) => {
        if (res.data.code == 200) {
          this.stock.stockBaseData = res.data.data;
        }
      });
      this.$http.get(apiUrl + "stock/stockMap.json").then((res) => {
        if (res.data.code == 200) {
          this.stock.mapData = res.data.data;
        }
      });
    },
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), "HH: mm: ss");
        this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
        this.dateWeek = this.weekday[new Date().getDay()];
      }, 1000);
      //this.dataTiming = setInterval(this.getSalesData, 30000);
    },
    showScreen(idx) {
      this.curPageIdx = idx;
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/index.scss";
</style>
