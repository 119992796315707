<template>
  <div class="contain" :style="extraStyle">
    <div class="line" style="width: 100%"></div>
    <div
      class="content"
      style="
        width: 2248px;
        height: 326px;
        background-image: url('img/titleBG.png');
        background-size: contain;
      "
    >
      {{ title }}
    </div>
    <div class="line" style="width: 100%"></div>
  </div>
</template>

<script>
export default {
  data() {
    return { extraStyle: { "flex-direction": "row" } };
  },
  components: {},
  props: {
    title: { type: String, required: true },
  },
  watch: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
.contain {
  width: 100%;
  overflow: visible;
  height: 240px;
  padding-top: 120px;
  display: flex;
  justify-content: center;

  div {
    height: 120px;
  }

  .line {
    border-bottom: solid 12px white;
    height: 66px;
  }

  .content {
    flex-shrink: 0;
    margin-top: -120px;
    padding-top: 100px;
    letter-spacing: 40px;
    font-size: 120px;
    text-align: center;
    color: white;
    line-height: 1;
  }
}
</style>
