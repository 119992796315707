<template>
  <div class="content" v-if="data">
    <div class="flex-line">
      <div style="width: 1700px; height: 1100px">
        <div class="title0">实时环境监控指标</div>
        <div class="flex-line control-box">
          <round-progress :value="20" color="#F18B4D" :size="350" style="margin-right: 100px;">
            <div>
              <div style="font-size: 70px; text-align: center">温度</div>
              <num-field
                :value="24"
                :numSize="120"
                unit="℃"
                :unitSize="50"
                unitColor="#697c94"
              ></num-field>
            </div>
          </round-progress>
          <img src="/img/pm25.webp" />
          <div style="margin-left:30px; width:400px">
            <div style="font-size: 70px; margin-bottom: 20px;">PM 2.5</div>
            <num-field
              :value="46"
              :numSize="120"
              unit="mg/m³"
              :unitSize="50"
              unitColor="#697c94"
            ></num-field>
          </div>
          <img src="/img/pm10.webp" />
          <div style="margin-left:30px; width:400px">
            <div style="font-size: 70px; margin-bottom: 20px;">PM 10</div>
            <num-field
              :value="3"
              :numSize="120"
              unit="mg/m³"
              :unitSize="50"
              unitColor="#697c94"
            ></num-field>
          </div>
        </div>
        <div class="flex-line control-box">
          <round-progress :value="60" color="#528BE4" :size="350" style="margin-right: 100px;">
            <div>
              <div style="font-size: 70px; text-align: center">湿度</div>
              <num-field
                :value="58"
                :numSize="120"
                unit="%rh"
                :unitSize="50"
                unitColor="#697c94"
              ></num-field>
            </div>
          </round-progress>
          <vert-bar :value="70" />
          <div style="margin-left:30px; width:400px">
            <div style="font-size: 70px; margin-bottom: 20px;">紫外线</div>
            <num-field
              :value="343"
              :numSize="120"
              unit="nm"
              :unitSize="50"
              unitColor="#697c94"
            ></num-field>
          </div>
          <vert-bar :value="30" />
          <div style="margin-left:30px; width:400px">
            <div style="font-size: 70px; margin-bottom: 20px;">氨气</div>
            <num-field
              :value="22"
              :numSize="120"
              unit="mg/m³"
              :unitSize="50"
              unitColor="#697c94"
            ></num-field>
          </div>
        </div>
      </div>
      <div class="v-split" style="height: 1100px" />
      <div style="width: 1350px; height: 1100px">
        <div class="title0">产地监控</div>
        <area-btns
          style="margin-top: 40px"
          :margin="30"
          :changeInterval="10000"
          :list="cctvList"
          v-model="cctvIdx"
        ></area-btns>
        <video
          muted
          autoplay
          loop
          style="
            width: 1300px;
            background-color: black;
            border-radius: 40px;
            margin-top: 50px;
            height: 850px;
          "
          :src="data.cctvList[cctvIdx].url"
        ></video>
      </div>
    </div>
  </div>
</template>

<script>
import AreaBtns from "../common/areaBtns.vue";
import NumField from "../common/numField.vue";
import RoundProgress from "../common/roundProgress.vue";
import VertBar from '../common/vertBar.vue';
export default {
  data() {
    return {
      cctvList: [],
      cctvIdx: 0,
    };
  },
  components: { AreaBtns, RoundProgress, NumField, VertBar },
  props: {
    data: {
      type: Object,
    },
  },
  watch: {
    data: {
      handler(val) {
        this.cctvList = [];
        val.cctvList.forEach((it) => {
          this.cctvList.push(it.name);
        });
      },
    },
  },
  mounted() {
    if (this.data) {
      this.cctvList = [];
      this.data.cctvList.forEach((it) => {
        this.cctvList.push(it.name);
      });
    }
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.content {
  color: #244b66;
  line-height: 1;
}
.city-title {
  padding: 20px;
  color: #697c94;
  font-size: 60px;
  justify-content: space-between;
  div {
    text-align: center;
    width: 400px;
  }
}
.title0 {
  font-size: 75px;
  margin-bottom: 20px;
}
.title1 {
  color: #697c94;
  font-size: 75px;
  margin-bottom: 20px;
}
.title2 {
  font-size: 60px;
  margin-right: 40px;
}
.control-box {
  height: 450px;
  margin-top: 50px;
  padding-top: 50px;
  border-top: 4px solid #c0ceda;
}
.changeBtn {
  width: 450px;
  height: 72px;
  border: solid 4px #697c94;
  margin-right: 20px;
  font-size: 50px;
  line-height: 70px;
  text-align: center;
  cursor: pointer;
  transition: color 1s, background-color 1s;

  &.active {
    background-color: #528be4;
    color: white;
  }
}
</style>
