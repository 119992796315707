<template>
  <div class="content" style="margin-top: 50px; position: relative">
    <div
      style="
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        text-align: center;
      "
    >
      <div
        class="map-contain"
        style="
          display: inline-block;
          position: relative;
          width: 5000px;
          height: 3536px;
        "
        @mousedown="mousedown"
      >
        <div
          v-for="(p, idx) in data.points"
          :key="p.name"
          class="point"
          :style="{
            left: p.location[0] - 100 + 'px',
            top: p.location[1] - 100 + 'px',
          }"
        >
          <img :src="`/img/dian${p.type}.webp`" />
          <div
            class="infoPanel"
            :style="{ opacity: Math.floor(idx / 2) == curIdx ? 1 : 0 }"
          >
            <div class="main">
              <div class="name">{{ p.name }}</div>
              <div class="flex-line">
                <div class="info">接入产业<br />平台占比</div>
                <div class="percent">{{ p.percent }}%</div>
              </div>
            </div>
            <div class="bottom"></div>
          </div>
        </div>
        <div
          class="title1"
          style="
            position: absolute;
            top: 600px;
            left: 0;
            right: 0;
            text-align: center;
          "
        >
          全国种植基地分布
        </div>
        <div class="legendPanel">
          <div class="flex-line">
            <div class="dot" style="background-color: #2fafac"></div>
            <div>哈密瓜产业基地</div>
          </div>
          <div class="flex-line">
            <div class="dot" style="background-color: #f18b4d"></div>
            <div>哈密瓜研发基地</div>
          </div>
        </div>
        <video
          style="width: 5000px; height: 3536px"
          muted="true"
          autoplay="true"
          loop="true"
          src="video/map-blank.m4v"
        ></video>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      curIdx: 0,
    };
  },
  components: {},
  computed: {},
  props: {
    data: {
      type: Object,
    },
  },
  watch: {
    data: {},
  },
  mounted() {
    setInterval(() => {
      this.curIdx++;
      if (this.curIdx >= Math.ceil(this.data.points.length / 2)) {
        this.curIdx = 0;
      }
      console.log(this.curIdx);
    }, 5000);
  },
  methods: {
    mousedown(e) {
      var { offsetX, offsetY } = e;
      console.log([offsetX, offsetY]);
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  color: #244b66;
  line-height: 1;
  font-size: 50px;
  height: 100%;
}
.map-contain {
  .point {
    width: 200px;
    height: 200px;
    overflow: visible;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    .infoPanel {
      position: absolute;
      z-index: 10;
      bottom: 150px;
      opacity: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      .main {
        background: #ffffff80;
        backdrop-filter: blur(20px);
        width: 570px;
        height: 320px;
        border-radius: 40px;
      }
      .bottom {
        background: #ffffff80;
        backdrop-filter: blur(20px);
        width: 70px;
        height: 40px;
        clip-path: polygon(0 0, 70px 0px, 35px 40px);
      }
      transition: opacity 0.5s;
      .name {
        padding-top: 20px;
        width: 450px;
        margin: auto;
        height: 150px;
        font-size: 55px;
        line-height: 130px;
        border-bottom: 3px solid #818a93;
        margin-bottom: 40px;
      }
      .info {
        margin-left: 80px;
        width: 200px;
        font-size: 50px;
        color: #697c94;
      }
      .percent {
        width: 250px;
        font-size: 90px;
        color: #2c948f;
      }
    }
  }
}
.floatInfo {
  text-align: left;
  color: #697c94;
  font-size: 80px;
  position: absolute;
  right: 0px;
  bottom: 1100px;
  .item {
    margin-top: 30px;
  }

  .num-field {
    margin-left: 30px;
  }
}

.title1 {
  color: #697c94;
  font-size: 100px;
  letter-spacing: 20px;
  margin-bottom: 20px;
}

.legendPanel {
  position: absolute;
  left: 1000px;
  bottom: 500px;
  width: 550px;
  height: 250px;
  border: 2px solid #697c94;
  border-radius: 40px;
  background-color: #dae6ed;
  color: #697c94;
  padding: 40px;
  .dot {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    margin: 30px 30px;
  }
}
</style>
