<template>
  <div class="box" :style="styleData">
    <slot></slot>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  props: {
    styleData: { type: Object },
  },
  watch: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
.box {
  border: white 4px solid;
  background-color: #d5e1e9;
  padding: 60px 90px;
  border-radius: 60px;
  box-shadow: 50px 50px 50px 10px rgba(0, 0, 0, 0.1);
}
</style>
