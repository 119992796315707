<template>
  <div class="content" v-if="data">
    <div class="flex-line">
      <div style="width: 1750px; height: 900px">
        <div class="title0">实时环境监测</div>
        <div class="flex-line control-box" style="height: 750px">
          <round-progress
            :value="20"
            color="#F18B4D"
            :size="350"
            style="margin-right: 50px"
          >
            <div>
              <div style="font-size: 70px; text-align: center">温度</div>
              <num-field
                :value="showData.tempr"
                :numSize="120"
                unit="℃"
                :unitSize="50"
                unitColor="#697c94"
              ></num-field>
            </div>
          </round-progress>
          <round-progress
            :value="60"
            color="#528BE4"
            :size="350"
            style="margin-right: 100px"
          >
            <div>
              <div style="font-size: 70px; text-align: center">湿度</div>
              <num-field
                :value="showData.wet"
                :numSize="120"
                unit="%rh"
                :unitSize="50"
                unitColor="#697c94"
              ></num-field>
            </div>
          </round-progress>
          <div>
            <div class="flex-line" style="align-items: baseline">
              <img class="alertImg" src="img/alert.png" />
              <div style="font-size: 70px; margin-right: 50px">预警信息</div>
              <num-field
                :value="3"
                :padZero="3"
                :numSize="120"
                unit="个"
                :unitSize="50"
                unitColor="#697c94"
              ></num-field>
            </div>
            <div
              class="flex-line"
              style="align-items: baseline; margin-top: 150px"
            >
              <img class="alertImg" src="img/alert.png" />
              <div style="font-size: 70px; margin-right: 50px">异常信息</div>
              <num-field
                :value="1"
                :padZero="3"
                :numSize="120"
                unit="个"
                :unitSize="50"
                unitColor="#697c94"
              ></num-field>
            </div>
          </div>
        </div>
      </div>
      <div class="v-split" style="height: 900px; margin-right: 100px" />
      <div style="width: 1300px; height: 900px">
        <div class="title0">阿勒泰仓库视频监控</div>
        <video
          muted
          autoplay
          loop
          style="
            width: 1260px;
            background-color: black;
            border-radius: 40px;
            margin-top: 50px;
            height: 720px;
          "
          :src="data.cctvList[cctvIdx].url"
        ></video>
      </div>
    </div>
  </div>
</template>

<script>
import NumField from "../common/numField.vue";
import RoundProgress from "../common/roundProgress.vue";
export default {
  data() {
    return {
      cctvList: [],
      cctvIdx: 0,
      showData: {},
    };
  },
  components: { RoundProgress, NumField },
  props: {
    data: {
      type: Object,
    },
  },
  watch: {
    data: {
      handler(val) {
        this.showData = Object.assign({}, this.data);
        this.cctvList = [];
        val.cctvList?.forEach((it) => {
          this.cctvList.push(it.name);
        });
      },
    },
  },
  mounted() {
    if (this.data) {
      this.showData = Object.assign({}, this.data);
      this.cctvList = [];
      this.data.cctvList?.forEach((it) => {
        this.cctvList.push(it.name);
      });
    }
    setInterval(() => {
      var data = Object.assign({}, this.data);
      data.tempr *= 0.9 + Math.random() * 0.2;
      data.wet *= 0.9 + Math.random() * 0.2;
      this.showData = data;
    }, 3000);
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.content {
  color: #244b66;
  line-height: 1;
}
.city-title {
  padding: 20px;
  color: #697c94;
  font-size: 60px;
  justify-content: space-between;
  div {
    text-align: center;
    width: 400px;
  }
}
.title0 {
  font-size: 75px;
  margin-bottom: 20px;
}
.title1 {
  color: #697c94;
  font-size: 75px;
  margin-bottom: 20px;
}
.title2 {
  font-size: 60px;
  margin-right: 40px;
}
.control-box {
  margin-top: 50px;
  padding-top: 50px;
  border: solid #c0ceda;
  border-width: 4px 0 4px 0;
}
.alertImg {
  width: 120px;
  height: 120px;
  margin-right: 20px;
}
.changeBtn {
  width: 450px;
  height: 72px;
  border: solid 4px #697c94;
  margin-right: 20px;
  font-size: 50px;
  line-height: 70px;
  text-align: center;
  cursor: pointer;
  transition: color 1s, background-color 1s;

  &.active {
    background-color: #528be4;
    color: white;
  }
}
</style>
