<template>
  <div class="content" style="position: relative">
    <div class="flex-line" style="position: relative">
      <div class="title1">价格数据分析</div>
      <div
        style="position: absolute; right: 0; color: #697c94; font-size: 50px"
      >
        单位 : 元/斤
      </div>
    </div>
    <area-btns
      :list="areas"
      :value="sumType"
      :changeInterval = "3000"
      @input="(e) => setSumType(e)"
      style="height: 80px; margin-top: 40px; position: relative; z-index: 10"
    ></area-btns>
    <div
      style="
        position: absolute;
        z-index: 1;
        height: 630px;
        bottom: 0;
        left: 0;
        right: 0;
      "
    >
      <Echart
        v-if="data"
        :options="optionBar"
        id="areaPriceChart"
        width="3100px"
        height="630px"
      ></Echart>
    </div>
  </div>
</template>

<script>
import Echart from "@/common/echart";
import AreaBtns from "../common/areaBtns.vue";
export default {
  data() {
    return {
      sumType: 0,
      areas: [
        "华北地区",
        "华东地区",
        "东北地区",
        "华中地区",
        "华南地区",
        "西南地区",
        "西北地区",
      ],
      areaFields: [
        "huabei",
        "huadong",
        "dongbei",
        "huazhong",
        "huanan",
        "xinan",
        "xibei",
      ],
      optionBar: {
        legend: {
          show: true,
          z: 10,
          data: ["一级瓜", "二级瓜", "三级瓜"],
          top: 20,
          left: "right",
          orient: "horizontal",
          icon: "rect",
          padding: 30,
          itemGap: 30,
          itemWidth: 40,
          itemHeight: 40,
          textStyle: {
            color: "#697c94",
            fontSize: 40,
          },
        },
        grid: {
          top: 180,
          left: 80,
          right: 0,
        },
        xAxis: [
          {
            axisLabel: {
              color: "#697c94",
              fontSize: 40,
            },
            type: "category",
            axisTick: { show: false },
            data: [],
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              color: "#697c94",
              fontSize: 40,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#697c94",
                width: 3,
              },
            },
          },
        ],
        series: [
          {
            name: "一级瓜",
            type: "line",
            data: [],
            showSymbol: false,
            lineStyle: {
              width: 10,
            },
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#43cecb", // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: "#30b0ad", // 100% 处的颜色
                },
              ],
            },
          },
          {
            name: "二级瓜",
            type: "line",
            data: [],
            showSymbol: false,
            lineStyle: {
              width: 10,
            },
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#f7c76e", // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: "#f18c4e", // 100% 处的颜色
                },
              ],
            },
          },
          {
            name: "三级瓜",
            type: "line",
            data: [],
            showSymbol: false,
            lineStyle: {
              width: 10,
            },
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#ff9090", // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: "#f56565", // 100% 处的颜色
                },
              ],
            },
          },
        ],
      },
    };
  },
  components: { Echart,AreaBtns },
  props: {
    data: {},
  },
  watch: {
    data: {
      handler(val) {
        this.refreshChart(val);
      },
    },
  },
  mounted() {
    if (this.data) {
      this.refreshChart(this.data);
    }
  },
  methods: {
    setSumType(val) {
      this.sumType = val;
      this.refreshChart(this.data);
    },
    refreshChart(data) {
      var barOpt = Object.assign({}, this.optionBar);
      var barData = data.areaPrice;
      barOpt.xAxis[0].data = barData.xAxis;
      var field = this.areaFields[this.sumType];
      var fdata = barData[field];
      barOpt.series[0].data = fdata.L1;
      barOpt.series[1].data = fdata.L2;
      barOpt.series[2].data = fdata.L3;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  color: #244b66;
  line-height: 1;
  height: 100%;
}
.title1 {
  font-size: 75px;
  margin-bottom: 20px;
}
</style>
