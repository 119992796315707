<template>
  <div class="flex-line">
    <div class="idxNumOut" :style="{ 'border-color': data.color }">
      <div class="idxNum" :style="{ 'background-color': data.color }">
        {{ data.idx }}
      </div>
    </div>
    <div class="name">{{ data.name }}</div>
    <div class="barbg">
      <div
        class="bar"
        :style="{
          'background-color': data.color,
          width: (900 * data.value) / data.max + 'px',
        }"
      ></div>
    </div>
    <div class="value">{{ data.value }}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  props: {
    data: { type: Object, required: true },
  },
  watch: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
.flex-line {
  margin: 30px 0;
  align-items: baseline;
  color: #244b66;
  font-size: 60px;
}
.idxNumOut {
  width: 90px;
  height: 90px;
  border-radius: 100%;
  border: 2px solid white;
  background: transparent;
  padding: 10px;
  margin: 0 20px;
  .idxNum {
    width: 70px;
    height: 70px;
    font-size: 50px;
    border-radius: 100%;
    color: white;
    line-height: 70px;
    text-align: center;
  }
}
.name {
  width: 500px;
  margin: 0 20px;
}
.barbg {
  margin: 0 20px;
  width: 900px;
  height: 16px;
  border-radius: 8px;
  background-color: #bdccd8;

  .bar {
    height: 16px;
    border-radius: 8px;
  }
}
.value {
  width: 250px;
  text-align: right;
}
</style>
