<template>
  <div class="content" v-if="data">
    <div class="flex-line">
      <div style="width: 100%; height: 1100px">
        <div class="title0">各省哈密瓜品种种植情况</div>
        <div class="flex-line city-title">
          <div style="text-align: left">省份</div>
          <div>西州蜜</div>
          <div>伽师瓜</div>
          <div>网纹蜜瓜</div>
          <div>洋香瓜</div>
          <div>香妃甜瓜</div>
          <div>东湖瓜</div>
          <div>金蜜宝</div>
          <div>红心脆</div>
          <div>加格达</div>
          <div>预田香</div>
        </div>
        <p-item
          v-for="(item, idx) in data.provinces"
          :active="idx == cityIdx"
          :key="idx"
          :data="item"
        ></p-item>
      </div>
    </div>
  </div>
</template>

<script>
import PItem from "./provincePlantItem.vue";
export default {
  data() {
    return {
      cityIdx:0,
    };
  },
  components: { PItem },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  watch: {
  },
  mounted() {
    setInterval(() => {
      this.cityIdx++;
      if (this.cityIdx >= this.data.provinces.length) {
        this.cityIdx = 0;
      }
    }, 2000);
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.content {
  color: #244b66;
  line-height: 1;
}
.city-title {
  padding: 20px;
  color: #697c94;
  font-size: 60px;
  justify-content: space-between;
  div {
    text-align: center;
    width: 270px;
  }
}
.title0 {
  font-size: 75px;
  margin-bottom: 20px;
}
.title1 {
  color: #697c94;
  font-size: 75px;
  margin-bottom: 20px;
}
.title2 {
  font-size: 60px;
  margin-right: 40px;
}
.changeBtn {
  width: 450px;
  height: 72px;
  border: solid 4px #697c94;
  margin-right: 20px;
  font-size: 50px;
  line-height: 70px;
  text-align: center;
  cursor: pointer;
  transition: color 1s, background-color 1s;

  &.active {
    background-color: #528be4;
    color: white;
  }
}
</style>
