var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"flex-line",staticStyle:{"margin":"80px 240px 0"}},[_c('div',{staticClass:"title0",staticStyle:{"margin-right":"50px"}},[_vm._v("产区分析")]),_c('area-btns',{attrs:{"list":_vm.areas,"width":350,"height":80,"margin":40,"changeInterval":3000},model:{value:(_vm.areaIdx),callback:function ($$v) {_vm.areaIdx=$$v},expression:"areaIdx"}})],1),_c('div',{staticClass:"flex-line"},[_c('chart-box',{attrs:{"styleData":{
        width: '1870px',
        height: '860px',
        margin: '40px 0 0 180px',
      }}},[_c('product-month',{attrs:{"data":_vm.data,"idx":_vm.areaIdx}})],1),_c('chart-box',{attrs:{"styleData":{
        width: '1400px',
        height: '860px',
        margin: '40px 0 0 60px',
      }}},[_c('season-product',{attrs:{"data":_vm.data,"idx":_vm.areaIdx}})],1)],1),_c('div',{staticClass:"flex-line"},[_c('chart-box',{attrs:{"styleData":{
        width: '3330px',
        height: '850px',
        margin: '80px 0 0 180px',
      }}},[_c('time-product',{attrs:{"data":_vm.data,"idx":_vm.areaIdx}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }