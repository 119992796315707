<template>
  <div class="content">
    <div class="flex-line" style="position: relative">
      <div class="title1">实时库存分析</div>
    </div>
    <Echart
      v-if="data"
      :options="optionPie"
      id="stockQtyChart"
      width="100%"
      height="630px"
    ></Echart>
  </div>
</template>

<script>
import Echart from "@/common/echart";
export default {
  data() {
    return {
      curIdx:0,
      optionPie: {
        tooltip: {
          show: false,
        },
        legend: {
          top: "center",
          right: 10,
          orient: "vertical",
          formatter: "",
          itemWidth: 60,
          itemHeight: 60,
          itemGap: 50,
          data: [
            { name: "1级瓜", icon: "circle", itemStyle: { color: "#43cecb" } },
            { name: "2级瓜", icon: "circle", itemStyle: { color: "#f7c76e" } },
            { name: "3级瓜", icon: "circle", itemStyle: { color: "#f56565" } },
          ],
          textStyle: {
            color: "#244b66",
            rich: {
              name: { fontSize: 60 },
              value: { fontSize: 80 },
            },
          },
        },
        title: {
          text: "{text|库存总量}\n{value|1.12}\n{text|万吨}",
          left: "25%",
          top: "center",
          textAlign: "center",
          textStyle: {
            fontWeight: "normal",
            color: "#244b66",
            rich: {
              text: { fontSize: 60 },
              value: { fontSize: 100 },
            },
          },
        },
        series: [
          {
            type: "pie",
            center: ["25%", "50%"],
            radius: ["60%", "80%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: false,
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              {
                value: 1048,
                name: "1级瓜",
                itemStyle: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 1,
                    x2: 0,
                    y2: 0,
                    colorStops: [
                      {
                        offset: 0,
                        color: "#43cecb", // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: "#30b0ad00", // 100% 处的颜色
                      },
                    ],
                  },
                },
              },
              {
                value: 735,
                name: "2级瓜",
                itemStyle: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 1,
                    y2: 0,
                    colorStops: [
                      {
                        offset: 0,
                        color: "#f7c76e", // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: "#f18c4e00", // 100% 处的颜色
                      },
                    ],
                  },
                },
              },
              {
                value: 580,
                name: "3级瓜",
                itemStyle: {
                  color: {
                    type: "linear",
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [
                      {
                        offset: 0,
                        color: "#f56565", // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: "#ff909000", // 100% 处的颜色
                      },
                    ],
                  },
                },
              },
            ],
          },
        ],
      },
    };
  },
  components: { Echart },
  props: {
    data: {},
  },
  watch: {
    data: {
      handler(val) {
        this.refreshChart(val);
      },
    },
  },
  mounted() {
    if (this.data) {
      this.refreshChart(this.data);
      setInterval(() => {
        this.changeIdx();
      }, 5000);
    }
  },
  methods: {
    changeIdx() {
      this.curIdx++;
      if (this.curIdx >= 3) {
        this.curIdx = 0;
      }
      this.refreshChart(this.data);
    },

    refreshChart(data) {
      var barOpt = Object.assign({}, this.optionPie);
      var barData = data.stockQty[this.curIdx];
      barOpt.series[0].data[0].value = barData["1级瓜"];
      barOpt.series[0].data[1].value = barData["2级瓜"];
      barOpt.series[0].data[2].value = barData["3级瓜"];
      var total = (
        (barData["1级瓜"] + barData["2级瓜"] + barData["3级瓜"]) /
        10000.0
      ).toFixed(2);
      barOpt.title.text = `{text|库存总量}\n{value|${total}}\n{text|万吨}`;
      barOpt.legend.formatter = (name) => {
        return `{name|${name}  }{value|${barData[name]}}}{name|吨}`;
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  color: #244b66;
  line-height: 1;
}
.title1 {
  font-size: 75px;
  margin-bottom: 20px;
}
.changeBtn {
  width: 300px;
  height: 72px;
  border: solid 4px #697c94;
  margin-right: 20px;
  font-size: 50px;
  line-height: 70px;
  text-align: center;
  cursor: pointer;
  transition: color 1s, background-color 1s;

  &.active {
    background-color: #528be4;
    color: white;
  }
}
</style>
