<template>
  <div class="content" style="position: relative">
    <div class="flex-line">
      <div class="title1">质检信息概览</div>
    </div>
    <div
      style="
        position: absolute;
        text-align: center;
        width: 100%;
        padding-left: 30px;
        top: 350px;
        display: flex;
        flex-direction: column;
        align-items: center;
      "
    >
      <num-field :value="showData.total" :numSize="120"></num-field>
      <div style="font-size: 55px; color: #697c94">总数（吨）</div>
    </div>
    <Echart
      :options="chartOption"
      width="100%"
      height="850px"
      style="margin-top: -100px"
    ></Echart>
  </div>
</template>

<script>
import Echart from "@/common/echart";
import NumField from "../common/numField.vue";

export default {
  data() {
    return {
      curIdx: 0,
      chartOption: {
        title: {
          text: "质检总数与百分比",
          left: "center",
          top: "bottom",
          textStyle: {
            fontWeight: "normal",
            color: "#697c94",
            fontSize: 55,
          },
        },
        grid: {
          top: 200,
          left: 100,
          right: 100,
          bottom: 50,
        },
        legend: {
          data: ["待检", "新鲜", "不新鲜"],
          show: true,
          z: 10,
          top: 10,
          left: "right",
          padding: 10,
          itemGap: 30,
          itemWidth: 40,
          itemHeight: 25,
          textStyle: {
            color: "#697c94",
            fontSize: 45,
          },
        },
        toolbox: {
          show: false,
        },
        series: [
          {
            label: {
              formatter: ["{title|{b}}{abg|}", "{value|{c}%}"].join("\n"),
              rich: {
                title: {
                  color: "#244b66",
                  align: "center",
                  height: 100,
                  fontSize: 50,
                },
                value: {
                  color: "#244b66",
                  align: "center",
                  fontSize: 75,
                },
              },
              alignTo: "labelLine",
            },
            labelLine: {
              length: 50,
              length2: 50,
              lineStyle: {
                color: "#244b66",
                width: 5,
              },
            },
            type: "pie",
            radius: ["40%", "50%"],
            center: ["50%", "50%"],
            //roseType: "radius",
            color: ["#528be4", "#697c94", "#f18b4d"],
            data: [
              { value: 60, name: "新鲜" },
              { value: 10, name: "不新鲜" },
              { value: 30, name: "待检" },
            ],
          },
        ],
      },
      data: { total: 3600, val: [60, 10, 30] },
      showData: {},
    };
  },
  components: { Echart, NumField },
  props: {
    idx: { type: Number },
  },
  watch: {
    data: {
      // handler(val) {
      //   this.oriData = Object.assign({}, val);
      //   this.refreshChart(val, this.idx);
      // },
    },
    idx: {
      handler(val) {
        this.refreshChart(this.data, val);
      },
    },
  },
  mounted() {
    if (this.data) {
      this.oriData = Object.assign({}, this.data);
      this.refreshChart(this.data, this.idx);
    }
    setInterval(() => {
      var data = Object.assign({}, this.oriData);
      data.val = Object.assign([], this.oriData.val);
      data.total *= 0.9 + Math.random() * 0.2;
      data.val[0] = Math.round(data.val[0] * (0.9 + Math.random() * 0.2));
      data.val[1] = Math.round(data.val[1] * (0.9 + Math.random() * 0.2));
      data.val[2] = 100 - data.val[0] - data.val[1];
      this.refreshChart(data, this.idx);
    }, 3000);
  },
  methods: {
    refreshChart(data, idx) {
      this.showData = data;
      console.log(data, idx);
      var opt = Object.assign({}, this.chartOption);
      for (var i = 0; i < 3; i++) {
        opt.series[0].data[i].value = data.val[i];
      }
      this.chartOption = opt;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  color: #244b66;
  line-height: 1;
}
.title1 {
  font-size: 75px;
  margin-bottom: 20px;
}
.changeBtn {
  width: 300px;
  height: 72px;
  border: solid 4px #697c94;
  margin-right: 20px;
  font-size: 50px;
  line-height: 70px;
  text-align: center;
  cursor: pointer;
  transition: color 1s, background-color 1s;

  &.active {
    background-color: #528be4;
    color: white;
  }
}
</style>
