<template>
  <div class="content">
    <div class="flex-line" style="position: relative">
      <div class="title1">早熟瓜/中熟瓜/晚熟瓜产量</div>
    </div>
    <Echart
      v-if="data"
      :options="chartOption"
      id="timeProductChart"
      width="100%"
      height="730px"
      style="margin-top: -100px"
    ></Echart>
  </div>
</template>

<script>
import Echart from "@/common/echart";
const emptyArr=[null,null,null,null];
const colors = ["#F1894B", "#2CAEAB", "#6471D7"];
const areaColors = [
  {
    type: "linear",
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [
      {
        offset: 0,
        color: "#F1894B", // 0% 处的颜色
      },
      {
        offset: 1,
        color: "#F1894B00", // 100% 处的颜色
      },
    ],
    global: false, // 缺省为 false
  },
  {
    type: "linear",
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [
      {
        offset: 0,
        color: "#2CAEAB", // 0% 处的颜色
      },
      {
        offset: 1,
        color: "#2CAEAB00", // 100% 处的颜色
      },
    ],
    global: false, // 缺省为 false
  },
  {
    type: "linear",
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [
      {
        offset: 0,
        color: "#6471D7", // 0% 处的颜色
      },
      {
        offset: 1,
        color: "#6471D700", // 100% 处的颜色
      },
    ],
    global: false, // 缺省为 false
  },
];
export default {
  data() {
    return {
      curIdx: 0,
      chartOption: {
        color: colors,
        tooltip: {
          show: false,
        },
        grid: {
          top: 200,
          left: 100,
          right: 100,
          bottom: 50,
        },
        legend: {
          data: ["早熟瓜", "中熟瓜", "晚熟瓜"],
          show: true,
          z: 10,
          top: 0,
          left: "right",
          padding: 10,
          icon: "roundRect",
          itemGap: 40,
          itemWidth: 50,
          itemHeight: 10,
          textStyle: {
            color: "#697c94",
            fontSize: 50,
          },
        },
        xAxis: [
          {
            type: "category",
            axisTick: {
              alignWithLabel: true,
            },
            axisLabel: {
              color: "#697c94",
              fontSize: 40,
            },
            // prettier-ignore
            data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "单位：吨",
            nameTextStyle: {
              color: "#697c94",
              fontSize: 40,
            },
            nameGap: 40,
            position: "left",
            alignTicks: true,
            axisLine: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#697c94",
                width: 3,
              },
            },
            axisLabel: {
              color: "#697c94",
              fontSize: 40,
            },
          },
        ],
        series: [
          {
            name: "早熟瓜",
            type: "line",
            smooth:false,
            showSymbol: false,
            areaStyle: {
              color: areaColors[0],
            },
            lineStyle: {
              width: 5,
            },
            data: [],
          },
          {
            name: "中熟瓜",
            type: "line",
            smooth:false,
            showSymbol: false,
            areaStyle: {
              color: areaColors[1],
            },
            lineStyle: {
              width: 5,
            },
            data: [],
          },
          {
            name: "晚熟瓜",
            type: "line",
            smooth:false,
            showSymbol: false,
            areaStyle: {
              color: areaColors[2],
            },
            lineStyle: {
              width: 5,
            },
            data: [],
          },
        ],
      },
    };
  },
  components: { Echart },
  props: {
    data: {},
    idx: { type: Number },
  },
  watch: {
    data: {
      handler(val) {
        this.refreshChart(val, this.idx);
      },
    },
    idx: {
      handler(val) {
        this.refreshChart(this.data, val);
      },
    },
  },
  mounted() {
    if (this.data) {
      this.refreshChart(this.data, this.idx);
    }
  },
  methods: {
    refreshChart(data, idx) {
      var opt = Object.assign({}, this.chartOption);
      var d = data.timeprod[idx];
      opt.series[0].data = d[0];
      opt.series[1].data = emptyArr.concat(d[1]);
      opt.series[2].data = emptyArr.concat(emptyArr, d[2]);
      this.chartOption = opt;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  color: #244b66;
  line-height: 1;
}
.title1 {
  font-size: 75px;
  margin-bottom: 20px;
}
.changeBtn {
  width: 300px;
  height: 72px;
  border: solid 4px #697c94;
  margin-right: 20px;
  font-size: 50px;
  line-height: 70px;
  text-align: center;
  cursor: pointer;
  transition: color 1s, background-color 1s;

  &.active {
    background-color: #528be4;
    color: white;
  }
}
</style>
