<template>
  <div class="content">
    <div class="flex-line" style="position: relative">
      <div class="title1">评价雷达图</div>
      <div
        style="position: absolute; right: 0; color: #697c94; font-size: 50px"
      >
        {{ curName }}
      </div>
    </div>
    <Echart
      :options="option"
      id="pingjiaRadar"
      width="100%"
      height="680px"
    ></Echart>
  </div>
</template>

<script>
import Echart from "@/common/echart";
const nameList = ["淘宝天猫", "拼多多", "抖音"];
export default {
  data() {
    return {
      curIdx: 0,
      option: {
        grid: {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        },
        radar: {
          nameGap: 10,
          name: {
            fontSize: 50,
            color: "#244b66",
          },
          indicator: [
            { name: "价格", max: 10 },
            { name: "多样性", max: 10 },
            { name: "售后服务", max: 10 },
            { name: "质量", max: 10 },
            { name: "外观", max: 10 },
            { name: "营销策略", max: 10 },
          ],
        },
        series: [
          {
            areaStyle: {},
            type: "radar",
            data: [{ value: [9, 8.5, 7.8, 5.2, 6.6, 3.2] }],
            animationCurve: "easeOutBack",
          },
        ],
      },
    };
  },
  components: {
    Echart,
  },
  computed: {
    curName() {
      return nameList[this.curIdx];
    },
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  watch: {
    data: {
      handler(val) {
        this.setChartOpt(val);
      },
    },
  },
  mounted() {
    this.setChartOpt();
    setInterval(() => {
      this.nextIdx();
    }, 3000);
  },
  methods: {
    setChartOpt(val) {
      val = val || this.data;
      console.log(val);
      var d = val[this.curIdx];
      var newOpt = Object.assign({}, this.option);
      var it = newOpt.series[0];
      it.data[0].value = d.value;
      it.color = d.color;
      this.option = newOpt;
    },
    nextIdx() {
      this.curIdx++;
      if (this.curIdx >= 3) {
        this.curIdx = 0;
      }
      this.setChartOpt();
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  color: #244b66;
  line-height: 1;
  font-size: 50px;
}
.title1 {
  font-size: 75px;
  margin-bottom: 20px;
}
</style>
