<template>
  <div class="content" v-if="data">
    <div class="flex-line">
      <div style="width: 950px; height: 220px">
        <div class="title1">线上销售金额</div>
        <num-field
          :value="saleTotalAmount"
          :numSize="150"
          unit="万元"
          :digit="2"
          :unitSize="70"
        />
      </div>
      <div style="width: 800px; height: 220px">
        <div class="title1">线上销售量</div>
        <num-field
          :value="saleTotalQty"
          :numSize="150"
          unit="万吨"
          :digit="2"
          :unitSize="70"
        />
      </div>
    </div>
    <div class="flex-line" style="margin-top: 80px">
      <div style="width: 1770px; height: 780px">
        <div class="flex-line">
          <div class="flex-line" style="width: 950px; height: 80px">
            <div class="title2">今日实时销售额</div>
            <num-field
              :value="saleTodayAmount"
              :digit="2"
              :numSize="80"
              unit="万元"
              :unitSize="70"
            />
          </div>
          <div class="flex-line" style="width: 800px; height: 80px">
            <div class="title2">今日实时销售量</div>
            <num-field
              :value="saleTodayQty"
              :digit="2"
              :numSize="80"
              unit="吨"
              :unitSize="70"
            />
          </div>
        </div>
        <div
          class="flex-line"
          style="height: 80px; margin-top: 40px; position: relative"
        >
          <div
            class="changeBtn"
            :class="{ active: sumType == 0 }"
            @click="setSumType(0)"
          >
            近三年销售额趋势
          </div>
          <div
            class="changeBtn"
            :class="{ active: sumType == 1 }"
            @click="setSumType(1)"
          >
            今年分月销售趋势
          </div>
          <div
            style="
              position: absolute;
              right: 0;
              color: #697c94;
              font-size: 50px;
            "
          >
            单位 : 万元
          </div>
        </div>
        <Echart
          :options="optionBar"
          id="salePlateform"
          width="1770px"
          height="580px"
        ></Echart>
      </div>
      <div class="v-split" style="height: 780px" />
      <Echart
        :options="optionPie"
        id="saleChannel"
        width="1300px"
        height="780px"
      ></Echart>
    </div>
  </div>
</template>

<script>
import Echart from "@/common/echart";
import NumField from "../common/numField.vue";
export default {
  data() {
    return {
      incTime: 0,
      incAmountMax: 0,
      incAmountMin: 0,
      price: 4,
      saleTotalAmount: 0,
      saleTotalQty: 0,
      saleTodayAmount: 0,
      saleTodayQty: 0,
      sumType: 0,
      channelIdx: 0,
      optionBar: {
        legend: {
          show: true,
          z: 10,
          data: ["淘宝天猫", "拼多多", "抖音"],
          top: 0,
          left: "left",
          padding: 40,
          itemGap: 30,
          itemWidth: 36,
          itemHeight: 36,
          textStyle: {
            color: "#697c94",
            fontSize: 40,
          },
        },
        grid: {
          top: 120,
          left: 100,
          right: 0,
        },
        xAxis: [
          {
            axisLabel: {
              color: "#697c94",
              fontSize: 40,
            },
            type: "category",
            axisTick: { show: false },
            data: [],
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              color: "#697c94",
              fontSize: 40,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#697c94",
                width: 3,
              },
            },
          },
        ],
        series: [
          {
            name: "淘宝天猫",
            type: "bar",
            data: [],
            barWidth: 25,
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#43cecb", // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: "#30b0ad", // 100% 处的颜色
                },
              ],
            },
          },
          {
            name: "抖音",
            type: "bar",
            data: [],
            barWidth: 25,
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#f7c76e", // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: "#f18c4e", // 100% 处的颜色
                },
              ],
            },
          },
          {
            name: "拼多多",
            type: "bar",
            data: [],
            barWidth: 25,
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#ff9090", // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: "#f56565", // 100% 处的颜色
                },
              ],
            },
          },
        ],
      },
      optionPie: {
        title: {
          text: "销售形式\n占比",
          left: "center",
          top: "center",
          textStyle: {
            fontWeight: "normal",
            color: "#244b66",
            fontSize: 60,
          },
        },
        legend: {
          show: false,
        },
        toolbox: {
          show: true,
          feature: {
            mark: { show: true },
          },
        },
        series: [
          {
            label: {
              formatter: ["{title|{b}}{abg|}", "{value|{c}%}"].join("\n"),
              rich: {
                title: {
                  color: "#244b66",
                  align: "center",
                  height: 100,
                  fontSize: 60,
                },
                value: {
                  color: "#244b66",
                  align: "center",
                  fontSize: 80,
                },
              },
              alignTo: "labelLine",
            },
            labelLine: {
              length: 100,
              length2: 100,
              lineStyle: {
                color: "#244b66",
                width: 10,
              },
            },
            type: "pie",
            radius: ["50%", "60%"],
            roseType: "radius",
            color: ["#2fafac", "#f18b4d", "#697c94", "#528be4"],
            itemStyle: {
              borderRadius: "10%",
            },
            data: [],
          },
        ],
      },
    };
  },
  components: { NumField, Echart },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  watch: {
    data: {
      handler(val) {
        this.refreshChart(val);
        this.setAmountNumber(this.data);
      },
    },
  },
  mounted() {
    if (this.data) {
      this.refreshChart(this.data);
      this.setAmountNumber(this.data);
    }
    setInterval(() => {
      this.swapIdx();
      this.incAmount();
    }, 5000);
  },
  methods: {
    setAmountNumber(val) {
      this.incTime = val.increaseConfig.roundMinute * 12;
      this.incAmountMin = val.increaseConfig.amountIncrease.min / this.incTime;
      this.incAmountMax = val.increaseConfig.amountIncrease.max / this.incTime;
      this.price = val.increaseConfig.weightPrice;
      this.saleTotalAmount = val.saleTotal.amount;
      this.saleTotalQty = val.saleTotal.weight;
      this.saleTodayAmount = val.saleToday.amount;
      this.saleTodayQty = val.saleToday.weight;
    },
    incAmount() {
      if (this.incTime > 0) {
        this.incTime--;
        if (this.incTime > 0) {
          var changeAmt =
            Math.random() * (this.incAmountMax - this.incAmountMin) +
            this.incAmountMin;
          this.saleTotalAmount += changeAmt;
          this.saleTotalQty += changeAmt / this.price;
          this.saleTodayAmount += changeAmt;
          this.saleTodayQty += (changeAmt * 10000) / this.price;
        } else {
          this.setAmountNumber(this.data);
        }
      }
    },
    swapIdx() {
      this.channelIdx++;
      if (this.channelIdx >= 3) this.channelIdx = 0;
      this.setSumType(1 - this.sumType);
    },
    setSumType(val) {
      this.sumType = val;
      this.refreshChart(this.data);
    },
    refreshChart(data) {
      var newOpt = Object.assign({}, this.optionPie);
      newOpt.series[0].data = data.channel[this.channelIdx];
      this.optionPie = newOpt;
      var barOpt = Object.assign({}, this.optionBar);
      var barData = this.sumType ? data.monthSum : data.yearSum;
      barOpt.xAxis[0].data = barData.xAxis;
      barOpt.series[0].data = barData.tmall;
      barOpt.series[1].data = barData.dy;
      barOpt.series[2].data = barData.pdd;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  color: #244b66;
  line-height: 1;
}
.title1 {
  color: #697c94;
  font-size: 75px;
  margin-bottom: 20px;
}
.title2 {
  font-size: 60px;
  margin-right: 40px;
}
.changeBtn {
  width: 450px;
  height: 72px;
  border: solid 4px #697c94;
  margin-right: 20px;
  font-size: 50px;
  line-height: 70px;
  text-align: center;
  cursor: pointer;
  transition: color 1s, background-color 1s;
  &.active {
    background-color: #528be4;
    color: white;
  }
}
</style>
